import styled from 'styled-components';
export const TableContainer = styled.div`
  width:100%;
`;

export const TableOptions = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`;

export const TableOptionLeftSide = styled.div`
  display: flex;
`;

export const TableOptionRightSide = styled.div`
  display: flex;
`;

export const TableSearch = styled.div`
`;

export const TableRowDisplay = styled.select`
  margin-right: 10px;
`;

export const SearchBox = styled.input`
  padding: 8px;
  border: 1px solid #9a9a9a;
  border-radius: 3px;
  width: 90%;
`;

export const TableColumnsShowHide = styled.div`
  margin-right: 10px;
`;

export const TableDatas = styled.div`
  width:100%;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  width: 100%;
  &.inner-table{
    tr{

    }
    th{
      border: 1px #ddd solid;
      background: #f5f5f5;
    } 
    td{
      border: 1px dashed #eaeaea;
    } 
  }
`;

export const StyledThead = styled.thead`
  tr {
    background-color: transparent;
    border-bottom: 1px dashed #eaeaea;
    color: #393E46;
    text-align: left;
    &:last-child {
      border-bottom: 1px dashed #eaeaea !important;
    }
  }  
`;

export const StyledTbody = styled.tbody`  
  
  tr.active-row {
    font-weight: bold;
    color: #009879;
  }
`;

export const StyledTr = styled.tr`
  border-bottom: 1px dashed #eaeaea;
  &:last-child {
    border-bottom: 0;
  }
  &.cursor-pointer{
    cursor:pointer;
  }
`;

export const StyledTh = styled.th`
  padding: 0.75rem 0.75rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  box-sizing: content-box;
  padding-right: 0;
`;

export const StyledTd = styled.td`
  padding: ${props => props.padding ? props.padding : "0.5rem 0.75rem 0.5rem 0.75rem"};
  box-sizing: content-box;
  padding-right: 0;
  max-width: 150px;
  ul.action-links{
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    li{float:left;
    margin-right:10px;}
  }
  &.text-gray-800 {
    font-weight:bold;
  }
  p{margin:0;}
  > div {
    position: relative;
  
    .table-action-dropdown {
      flex-direction: column;    
      border-radius: 0.95rem;
      background-color: #fff;
      box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
      z-index: 105;
      padding: 0;
      margin: 0;
      list-style: none;
      padding-top: 1rem!important;
      padding-bottom: 1rem!important;
      width: 125px;
      display: flex;
      animation: menu-sub-dropdown-animation-fade-in .3s ease 1,menu-sub-dropdown-animation-move-up .3s ease 1;
      position: absolute;
      top: 40px;
      left: 0;
      .menu-item {
        display: block;
        padding: 0;
        padding-right: 0.75rem!important;
        padding-left: 0.75rem!important;
        button {
          color: #8e887a;
          border-radius: 0.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0;
          flex: 0 0 100%;        
          transition: none;
          outline: 0!important;
          padding: .5rem 1rem !important;        
          background: transparent;
          width: 100%;
          > div {
            width: 100%;
            text-align: left;
          }        
          &:hover {
            background: #f80;
            color: #fff;
          }
        }
      }
    }
  }

  .data-group {
    display: flex;
    align-items: center;
    .image {
      width: 50px;
      height: 50px;
      display: flex;
      overflow: hidden;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ccc;
      justify-content: center;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .details {
      margin-left: 10px;
      span {
        display: block;
        &.main {
          font-family: 'PoppinsBold';
        }
      }
    }    
  }
`;

export const ColumnShowHide = styled.div`
width: 200px;
`;

export const Input = styled.input`
  appearance: auto;
  padding: 0.5em;
  margin: 0.5em;
  color: palevioletred;
  background: papayawhip;
  border: none;
  border-radius: 3px;
`;

export const DropContainer = styled.div`
  position: relative;
  display: inline-block;
  div {
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background:#fff;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;    text-transform: capitalize;
  }
  
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
  `;
export const DropButton = styled.button`
  
  `;

export const TablePagination = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  input {
    width: 60px;
    padding: 5px;
    text-align: center;
    margin-left: 5px;
  }
  span {
    display: flex;
    align-items: center;
    strong {
      margin: 0 5px;
    }
  }
  button{
    display: block;
    margin-left: 5px;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
`;

export const ActionLink = styled.div`

ul{
  display: flex;
  margin: 0;
  padding: 0;
  li{
    display: flex;
    margin-right: 10px;
    cursor: pointer;
  }
}

`
