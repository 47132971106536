import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useHistory } from "react-router-dom";
import { baseURL, getToken, setUserSession } from '../Shared/Utils/Common';
import {useToasts} from 'react-toast-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { storeAuthRefToken, storeAuthToken } from '../Shared/Utils/authToken';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import OtpInput from 'react-otp-input';

function ForgotPassword(props) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isApiCompleted, setIsApiCompleted] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [serverStatus, setServerStatus] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(location.state ? location.state.phoneNumber : '')
  const [isValidPhone, setIsValidPhone] = useState(true)
  const [isVerifiedOtp, setIsVerifiedOtp] = useState(false)
  const [otp, setOtp] = useState('')
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [otpSessionId, setOtpSessionId] = useState(location.sessionId ? location.sessionId : '')
  const [isOTPVerifying, setIsOTPVerifying] = useState(false)
  const [showGenerateOtp, setShowGenerateOtp] = useState(false)
  const [otpSentCount, setOtpSentCount] = useState(location.sessionId ? 1 : 0)
  const [counter, setCounter] = useState(59);
  const maxLimit = 3
  const token = getToken()

  const sendOTP = async(phone) => {
    
    if(!isOtpSent)
    {
      await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/json',
          'Authorization':  token ? `Bearer ${token}` : ''
        },
        url: `${baseURL()}user/v1/otp/generate`,
        data: {'mobile':phone,'otp-type':'ForgotPassword'}
      }).then(function (response) {
        setIsOtpSent(true)
        if(response['data']['session-id'])
        {
          setOtpSessionId(response['data']['session-id'])
          addToast('OTP sent to your phone number. Please check and verify it.', { appearance: 'success', autoDismiss: true});
          setShowGenerateOtp(false)
          setIsValidPhone(true)
          let tempcnt = otpSentCount
          tempcnt = tempcnt +1
          setOtpSentCount(tempcnt)
          if(tempcnt < maxLimit)
          {
            setCounter(59)
          }          
        }
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        addToast(error.message, { appearance: 'error',autoDismiss: true });
      });
    } 
  }
  
  const validateOTP = async() => {
    setIsLoading(true)
    await axios({
      method: 'post',
      headers: { 'Content-Type': 'application/json',
        'Authorization':  token ? `Bearer ${token}` : ''
      },
      url: `${baseURL()}user/v1/otp/validate`,
      data: {'mobile':phoneNumber,'session-id':otpSessionId,'otp':otp}
    }).then(function (response) {
      if(response['data']['status-code']===200)
      {
        addToast(response['data']['message'], { appearance: 'success', autoDismiss: true});
        setIsVerifiedOtp(true)
      }else{
        addToast(response['data']['message'], { appearance: 'error', autoDismiss: true});
      }
    }).catch(error => {
      addToast(error.response.data.message, { appearance: 'error',autoDismiss: true });
      setOtp('')
    });
    setLoading(false);
  }

  useEffect(() => {
    const timer =
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
     if(!isOtpSent && phoneNumber && phoneNumber.length >= 13)
      {
        sendOTP(phoneNumber)
        setIsOtpSent(true)
      }
  })

  useEffect(() => {
    if (otp.length === 6) {
       validateOTP()
    }
  },[otp])


  const RegistrationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(6, 'Should be more than 6 character')
      .required('Required'),
      confirm_password: Yup.string()
     .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
  });
  
  const handleOTPChange = (otp) => {
    setOtp(otp)
  }

  return (
    <div>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>JJ EDU: Learn Apptastically | Forgot password</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback" />
    <link rel="stylesheet" href="../../../plugins/fontawesome-free/css/all.min.css" />
    <link rel="stylesheet" href="../../../plugins/icheck-bootstrap/icheck-bootstrap.min.css" />
    <link rel="stylesheet" href="../../../dist/css/adminlte.min.css" />
    <div className={`login-box`}>
        <Fragment>
          <div className="card">
            <div className="card-body login-card-body">
              <div className="login-logo">
                <a href="/">
                  <img src="/dist/img/logo.svg" alt="JJ Edu Logo" className="brand-image"/></a>
              </div>
                <p className="login-box-msg">A 6 digit OPT verification code has been sent to your mobile number {phoneNumber}</p>
                <Formik
                  initialValues={
                    { 
                      new_password: '', 
                      confirm_password: ''
                    }
                  }
                  validationSchema={RegistrationSchema}

                  onSubmit={ async (values) => {
                    
                    if(phoneNumber && phoneNumber!='')
                    {
                    setLoading(true);
                    axios({
                      method: 'post',
                      headers: { 'Content-Type': 'application/json' },
                      url: `${baseURL()}user/v1/forgot-password/phone/${phoneNumber}`,
                      data: { 
                        new_password: values.new_password, 
                        confirm_password: values.confirm_password
                       }
                    }).then(function (response) {
                      setLoading(false);
                        if(response.status === 200)
                        {
                          addToast('New password updated successfully', { appearance: 'success', autoDismiss: true });    
                          props.history.push('/login')
                        }
                    }).catch(error => {
                      setLoading(false);
                      addToast(error.response.data.message, { appearance: 'error', autoDismiss: true });
                    });
                  }else{
                    addToast('Enter valid phone number', { appearance: 'error', autoDismiss: true });
                  }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                    <div className='row'>
                      {isValidPhone && isOtpSent && !isVerifiedOtp &&
                        <div className="input-group mb-3">
                          <OtpInput
                            value={otp}
                            onChange={handleOTPChange}
                            numInputs={6}
                            separator={<span>-</span>}
                            inputStyle='form-control'
                            containerStyle='otp-boxes'
                            isInputNum={true}
                          />
                          { counter > 0 && otpSentCount < maxLimit &&
                            <p style={{'paddingTop':'20px'}}>Resend code in 00 : {counter}</p>
                          }

                          { counter == 0 && otpSentCount < maxLimit && <>
                          <br />
                          <Link 
                          style={{'paddingTop':'15px'}}
                          to='#'
                          onClick={() => {setIsOtpSent(false); sendOTP(phoneNumber); }}
                          className="text-center">
                            Resend code
                          </Link>
                          </>
                          }

                          { otpSentCount == maxLimit && <>
                          <br /><p style={{'marginTop':'20px','color':'red'}}>Reached maximum attempt to send OTP</p>
                          </>
                          }
                        </div>
                      }

                      {isVerifiedOtp  && <>
                        <div className="input-group mb-3">
                      <Field type="password"
                          name='new_password'
                          className="form-control full-border"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.new_password}
                          placeholder="Password" />
                          <ErrorMessage name="new_password" component="div" className='mandatory_error' />
                      </div>

                      <div className="input-group mb-3">
                      <Field type="password"
                          name='confirm_password'
                          className="form-control full-border"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.confirm_password}
                          placeholder="Retype Password" />
                          <ErrorMessage name="confirm_password" component="div" className='mandatory_error' />
                      </div>
                      </>
                      }
                    </div>
                    
                    {isVerifiedOtp  && 
                    <div className='row text-center'>
                      <div className="col-12">
                        <button type="button" onClick={handleSubmit} disabled={loading} className="btn btn-primary btn-block brand-secondary-btn">{loading ? 'Loading...' : 'Submit'}</button>
                      </div>
                    </div>
                    }
                </Form>
                )}
              </Formik>
                  <div className="social-auth-links text-center">
              </div>
              {/* <Link 
                to='/login'
                className="text-center">
                  I already have a membership
                </Link> */}
            </div>
          </div>
        </Fragment>
      {/* } */}
    </div>
  </div>
  );
}

export default ForgotPassword;