import React, { Component } from 'react';
import FreshChat from 'react-freshchat'
import { getUser } from '../Utils/Common';

const Footer = () => {
  const user = getUser()
  
    return (<>
         <footer className="main-footer">
            <strong>Copyright © 2022 <span>JJ EDU</span>.</strong>  All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.0
            </div>
        </footer>
        <FreshChat
          token="32bea6f7-4e05-4a19-935e-599cafe48028"
          host="https://jojoedtechprivatelimited.freshchat.com"
          email={user.email}
          first_name={user.displayname}
        onInit={widget => {
            widget.user.setProperties({
              user_id: user.user_id,
              first_name: user.displayname,
              phone: user.phone,
            })
        }}
      />
    </>
    )
}

export default Footer
