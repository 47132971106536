import React, {useRef, useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { confirmAlert } from 'react-confirm-alert';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { formatDate } from '../Shared/Utils/dateTime';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Modal, PageLoader, Spinner } from '../Shared/Components';
import { baseURL, getToken, getUser, groupArrayOfObjects, removeUserSession } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import { TableDatas, StyledTable, StyledThead, StyledTbody, StyledTr, StyledTh, StyledTd } from '../Shared/Components/Common/DataTable/Styles';
import shaka from 'shaka-player/dist/shaka-player.ui.debug';
import { PDFReader } from 'reactjs-pdf-reader';

function MyCourses (props) {
  const user = getUser();
  const token = getToken();
  const history = useHistory();
  const controllerRef = useRef(null);
  const { addToast } = useToasts();
  const [assetLoaded, setAssetLoaded] = useState(false);
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [courseList, setCourseList] = useState( user.mycourses.mycourses && Object.entries(user.mycourses.mycourses).length > 0 ? user.mycourses.mycourses : {})
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('lecture')
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedLevel, setSelectedLevel] = useState({})
  const [selectedSubject, setSelectedSubject] = useState({})
  const [selectedTutor, setSelectedTutor] = useState({})
  const [selectedLecture, setSelectedLecture] = useState({})
  const [selectedPodcast, setSelectedPodcast] = useState({})

  const [showTutorDetails, setShowTutorDetails] = useState(false)
  
  const [lecturesData, setLecturesData] = useState({})
  const [podcastsData, setPodcastsData] = useState({})
  const [notesData, setNotesData] = useState({})
  
  const [othersData, setOthersData] = useState({})
  const [testseriesData, setTestseriesData] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTestseriesUploadModalOpen, setIsTestseriesUploadModalOpen] = useState(false)
  
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false)

  const [activeAudioUrl, setActiveAudioUrl] = useState('')
  const [activeNotesUrl, setActiveNotesUrl] = useState('')
  const [activeOthersUrl, setActiveOthersUrl] = useState('')
  const [clickedTestseries, setClickedTestseries] = useState('')
  const [selectedFile, setSelectedFile] = useState({})

  useEffect(() => {
    if(!isApiCompleted && Object.entries(selectedTutor).length >0)
    {
      loadLectures()
      loadPodcasts()
      loadNotes()
      loadTestseries()
      loadOthers()
      setIsApiCompleted(true)
      setIsLoading(false)
    }
  })

  useEffect(() =>{
    if(Object.entries(selectedLecture).length > 0)
    {
      loadLectureVideoPlay()
    }
  },[selectedLecture])

  const widevineLicenseURI = "https://example.com/widevine-licence-url";
  
  const onError = (event) => {
    console.error('Error code', event.detail.code, 'object', event.detail) // eslint-disable-line no-console
  }

  const onPlaybackError = (error) => {
    console.error('Error while loading playback: code', error.code, 'object', error);
  }

  async function loadLectureVideoPlay() {
    let video = controllerRef.current;
    let player = new shaka.Player(video);
    player.addEventListener('error', onError)

    player.getNetworkingEngine().registerRequestFilter(function(type, request) {
      if (type == shaka.net.NetworkingEngine.RequestType.MANIFEST ||
      type == shaka.net.NetworkingEngine.RequestType.SEGMENT) {
      request.headers['AUTHORIZATION'] = 'Bearer ' + token;
      }
    });
    
    let fullURL = `https://jjedutech.in:8443${selectedLecture.mpd_url}`

    player.load(fullURL).then(function() {
      setAssetLoaded(true);
      console.log('The video has now been loaded!');
    }).catch(onPlaybackError);
  }

  const handleCourseClick = (courseId) => {
    const selCourse = Object.keys(courseList).filter((c) => c === courseId)
    setSelectedCourse(courseList[selCourse])
  }

  const handleBackClick = () => {
    
    if(selectedTutor.length > 0)
    {
      setSelectedTutor({})
    }else if(selectedSubject.length > 0)
    {
      setSelectedSubject({})
      setSelectedTutor({})
    }else if(Object.entries(selectedLevel).length > 0)
    {
      setSelectedLevel({})
    }
    else if(Object.entries(selectedCourse).length > 0)
    {
      setSelectedCourse({})
    }
  }

  const handleLevelClick = (levelId) => {
    const levelSubjects = selectedCourse.levels.filter((lev) => lev.level_id === levelId)
    setSelectedLevel(levelSubjects)
  }

  const handleSubjectClick = (subjectId) => {
    const subjects = selectedLevel[0].subjects.filter((sub) => sub.subject_id === subjectId)
    setSelectedSubject(subjects)
    if(Object.values(subjects[0].data.tutors).length > 0)
    {
      handleTutorClick(Object.values(subjects[0].data.tutors)[0])
    }
  }

  const handleTutorClick = async (tutor) => {
    setSelectedTutor(tutor)
    setIsLoading(true)
    setIsApiCompleted(false)
  }

  const loadLectures = () => {
    api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/lecture/published`).then(function (response) {
      const chapterWiseLectures = groupArrayOfObjects(response.data, 'groupid')
      setLecturesData(chapterWiseLectures)
    }).catch(error => {
      addToast(error.message, { appearance: 'error',autoDismiss: true });
    });
  }

  const loadPodcasts = () => {
    api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/podcast`).then(function (response) {
      setPodcastsData(response.data)
    }).catch(error => {
      addToast(error.message, { appearance: 'error',autoDismiss: true });
    });    
  }

  const loadNotes = () => {
    api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/notes`).then(function (response) {
        const chapterWiseNotes = groupArrayOfObjects(response.data, 'groupid')
        setNotesData(chapterWiseNotes)
    }).catch(error => {
      addToast(error.message, { appearance: 'error',autoDismiss: true });
    });
  }

  const loadTestseries = () => {
    api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/testseries`).then(function (response) {
        setTestseriesData(response.data)
    }).catch(error => {
      addToast(error.message, { appearance: 'error',autoDismiss: true });
    });
  }

  const loadOthers = () => {
    api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/others`).then(function (response) {
        setOthersData(response.data)
    }).catch(error => {
      addToast(error.message, { appearance: 'error',autoDismiss: true });
    });
  }

  const handleLectureClick = (lectData) => {
    setSelectedLecture(lectData)
    setIsModalOpen(true)
    loadLectureVideoPlay()
    setActiveAudioUrl('')
    setActiveNotesUrl('')
    setActiveOthersUrl('')
  }

  const handlePodcastClick = (podcast) => {
    setIsModalOpen(true)
    setSelectedPodcast(podcast)
    // let fullURL = `https://staging.jjedutech.in:8443/${aurl}`
    let fullURL = `${baseURL()}files/system?file=${podcast.asset}`
    setActiveAudioUrl(fullURL)
    setActiveNotesUrl('')
    setActiveOthersUrl('')
    setSelectedLecture({})
  }

  const handleNotesClick = (url) => {
    let activeURL = `${baseURL()}files/system?file=${url}`
    setActiveNotesUrl(activeURL)
    setActiveAudioUrl('')
    setActiveOthersUrl('')
    setIsModalOpen(true)
    setSelectedLecture({})
  }

  const handleOthersClick = (url) => {
    setIsModalOpen(true)
    let activeURL = `${baseURL()}files/system?file=${url}`
    setActiveOthersUrl(activeURL)
    setActiveAudioUrl('')
    setActiveNotesUrl('')
    setSelectedLecture({})
  }

  const handleCartItemDelete = (cItems) => {
    
  }
  
const palyerConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
}

const handleAddNotes = () => {
  setIsNotesModalOpen(true)
}

const handleAddBookmarks = () => {
  setIsBookmarkModalOpen(true)
}

const getModalContent = () => {
  if(Object.entries(selectedLecture).length > 0)
  {
    return (<>
      <div className='card-header'>{selectedLecture.title}</div>
      <div className='card-body'>
        <div className='col'>
          <div className="video-container">
            <video className="shaka-video" ref={controllerRef} preload="none" autoPlay={false} width="100%" height="500" controls muted></video>
            <div className='custom-icons'>
              <div className='bookmark'>
                <i className="nav-icon fas fa-bookmark" onClick={() => handleAddBookmarks()} />
              </div>
              <div className='notes'>
                <i className="nav-icon fas fa-edit" onClick={() => handleAddNotes()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  if(activeAudioUrl !== '')
  {
    return (<>
      <div className='card-header'>
        {selectedPodcast.title}
      </div>
      <div className='card-body'>
        <div className='col'>
        <AudioPlayer
          src={`${baseURL()}files/system?file=${selectedPodcast.asset}`}
          volume={0.5}
          // Try other props!
        />
        </div>
      </div>
      </>
    )    
  }

  if(activeNotesUrl !== '')
  {
      return <PDFReader url={activeNotesUrl} showAllPage={true} />
  }

  if(activeOthersUrl !== '')
  {
      return <PDFReader url={activeOthersUrl} showAllPage={true} />
  }  
}

const handleDeleteAttachment = (tutor_id,attachment_id) => {
  confirmAlert({
    title: 'Confirm to delete',
    message: 'Are you sure want to delete',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          api.delete(`/api/user/v1/testseries/attachment/${attachment_id}?member_id=${user.user_id}`).then(function (response) {
            addToast('Attachment removed successfully', { appearance: 'success', autoDismiss: true });
            handleTutorClick(tutor_id)
          }).catch(error => {
            addToast(error, { appearance: 'error', autoDismiss: true });
          });
        }
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });
}

const handleUploadEvent = (testseries) => {
  setClickedTestseries(testseries)
  setIsTestseriesUploadModalOpen(true)
}

const handleFileUpload = (e) => {
  (async () => {
    setSelectedFile(e.target.files[0])
  })();
}

const TestseriesUploadSchema = Yup.object().shape({
  file: Yup.string()
    .min(2, 'Too Short!')
    .max(250, 'Too Long!')
});

const NotesSchema = Yup.object().shape({
  note_content: Yup.string()
    .min(6, 'Should be more than 6 character')
    .required('Required')    
});

    return ( 
    <>
      <Header
      handleCartItemDelete={(cItems) => handleCartItemDelete(cItems)}
      />
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">My Home</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header p-2">
                  Courses
              </div>
              <div className="card-body">
                { isLoading && <PageLoader/> }
                { courseList && Object.entries(courseList).length === 0 && 
                  <p className='text-center'>No records found</p>
                }
                { Object.entries(selectedCourse).length > 0 && 
                  <div className='row'>
                    <div className="col-md-12 col-sm-12 col-12 mb-15">
                      <div className="float-left">
                        <button onClick={() => handleBackClick()} type="button" className="btn btn-default"><i className="fas fa-arrow-left" /></button>
                        <ul className='inner-breadcrump'>
                          { Object.values(selectedCourse).length > 0 &&
                          <li><a>{selectedCourse.name}</a></li>
                          }
                          { Object.values(selectedLevel).length > 0 &&
                            <li><a>{ selectedLevel[0].name}</a></li>
                          }
                          { Object.values(selectedSubject).length > 0 &&
                            <li><a>{ selectedSubject[0].name}</a></li>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                }

                { Object.entries(selectedCourse).length === 0 && 
                  <div className='row'>  
                    { courseList && Object.values(courseList).map((course, index) => {
                      return (
                        <div key={index} onClick={()=>handleCourseClick(course.course_id)} className="col-md-4 col-sm-6 col-12 course-box">
                          <div className="info-box">
                            <span className="info-box-icon bg-info">
                            <img className="w-60 h-60 elevation-2" src={`${baseURL()}files/system?file=${course.icon}`} /></span>
                            <div className="info-box-content">
                              <span className="info-box-number">{course.name}</span>
                              <span className="info-box-text">{course.description}</span>
                            </div>
                            {/* /.info-box-content */}
                          </div>
                          {/* /.info-box */}
                        </div>
                        )
                      })
                    }
                  </div>
                }

                { Object.values(selectedLevel).length === 0 &&  
                  <div className='row'>
                  { Object.values(selectedCourse).length > 0 &&  Object.values(selectedCourse.levels).map((level, index) => {
                    return(
                      <div key={index} onClick={()=>handleLevelClick(level.level_id)} className="col-md-4 col-sm-6 col-12 course-box">
                        <div className="info-box">
                          <span className="info-box-icon bg-danger"><i className="far fa-star" /></span>
                          <div className="info-box-content">
                            <span className="info-box-text">{level.name}</span>
                            {/* <span className="info-box-number">0</span> */}
                          </div>
                        </div>
                      </div>
                      )
                    }) }
                  </div>
                }

                { Object.values(selectedLevel).length > 0 &&  Object.values(selectedSubject).length === 0 &&
                  <div className='row'>
                    <div className="col-md-12 col-sm-12 col-12 mb-15">
                      <ul className='subject-list'>
                        { Object.values(selectedLevel).length > 0 &&  Object.values(selectedLevel[0].subjects).map((subject, index) => {
                        return(
                          <li key={index} onClick={()=>handleSubjectClick(subject.subject_id)}>
                            {subject.name}
                          </li>
                          )
                        }) }
                      </ul>
                    </div>
                  </div>
                }
                    
                { Object.values(selectedSubject).length > 0 && selectedSubject[0].data && Object.values(selectedSubject[0].data.tutors).length > 0 && 
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 mb-15">
                      <ul className="users-list clearfix">
                        { Object.values(selectedSubject[0].data.tutors).map((tutor, index) => {
                          return(
                              <li className={selectedTutor.tutor_id === tutor.tutor_id ? 'active' : '' } key={index} onClick={()=> { return selectedTutor.tutor_id === tutor.tutor_id ? undefined : handleTutorClick(tutor) }}>
                                <img src={`${baseURL()}files/system?file=${tutor.avatar}`} alt={tutor.display_name} className="img-circle elevation-2" />
                                { selectedTutor.tutor_id === tutor.tutor_id && 
                                  <i className='fa fa-question-circle view-tutor-details' onClick={() => {setShowTutorDetails(true)}}></i>
                                }
                                <a className="users-list-name" title={tutor.data.profile.summary} href="#">{tutor.display_name}</a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                }

                { isLoading === false && Object.values(selectedTutor).length > 0 &&
                  <div className='row'>
                    <div className="col-12 col-sm-12">
                      <div className="card card-primary card-outline card-outline-tabs">
                        <div className="card-header p-0 border-bottom-0">
                          <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li className="nav-item">
                              <a className='nav-link active' id="custom-tabs-four-lectures-tab" data-toggle="pill" href="#custom-tabs-four-lectures" role="tab" aria-controls="custom-tabs-four-lectures" aria-selected="true">Lectures</a>
                            </li>
                            <li className="nav-item">
                              <a className='nav-link' id="custom-tabs-four-notes-tab" data-toggle="pill" href="#custom-tabs-four-notes" role="tab" aria-controls="custom-tabs-four-notes" aria-selected="true" >Notes</a>
                            </li>
                            <li className="nav-item">
                              <a className='nav-link'  id="custom-tabs-four-podcasts-tab" data-toggle="pill" href="#custom-tabs-four-podcasts" role="tab" aria-controls="custom-tabs-four-podcasts" aria-selected="true" >Podcasts</a>
                            </li>
                            <li className="nav-item">
                              <a className='nav-link'  id="custom-tabs-four-testseries-tab" data-toggle="pill" href="#custom-tabs-four-testseries" role="tab" aria-controls="custom-tabs-four-testseries" aria-selected="true" >Testseries</a>
                            </li>
                            <li className="nav-item">
                              <a className='nav-link' id="custom-tabs-four-others-tab" data-toggle="pill" href="#custom-tabs-four-others" role="tab" aria-controls="custom-tabs-four-others" aria-selected="false" >Others</a>
                            </li>
                          </ul>
                        </div>
                        <div className="card-body">
                          <div className="tab-content" id="custom-tabs-four-tabContent">
                            <div className="tab-pane fade show active" id="custom-tabs-four-lectures" role="tabpanel" aria-labelledby="custom-tabs-four-lectures-tab">
                            
                              {Object.entries(lecturesData).length > 0 &&
                                <div className="box-group" id="accordion">
                                  { Object.entries(lecturesData).map((lecture, index) => {
                                      return (
                                        <div key={index} className="card mb-15">
                                          <div className="card-header">
                                                  <h4 className="card-title w-100">
                                                  <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>Chapter {lecture[0]}</a>
                                                  </h4>
                                              </div>
                                              <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                                  <div className="card-body">
                                                  <TableDatas>
                                                  <StyledTable className='inner-table'>
                                                      <StyledTbody>
                                                      { lecture[1].length > 0 && lecture[1].map((data, i) => {
                                                          return(
                                                          <StyledTr className='cursor-pointer' key={i} onClick={() => handleLectureClick(data)}>
                                                              <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                                {`${data.groupid}.${data.subgroupid}.${data.sequenceid} ${data.title}`}
                                                                <small className='duration'>{data.duration}</small>
                                                              </StyledTd>
                                                          </StyledTr>
                                                          )
                                                      })}
                                                  </StyledTbody>
                                                  </StyledTable>
                                                  </TableDatas>
                                                  </div>
                                              </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              }
                            </div>

                            <div className="tab-pane fade" id="custom-tabs-four-notes" role="tabpanel" aria-labelledby="custom-tabs-four-notes-tab">
                            {Object.entries(notesData).length > 0 &&
                                <div className="box-group" id="accordion">
                                  { Object.entries(notesData).map((notes, index) => {
                                      return (
                                        <div key={index} className="card mb-15">
                                          <div className="card-header">
                                                  <h4 className="card-title w-100">
                                                  <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>Chapter {notes[0]}</a>
                                                  </h4>
                                              </div>
                                              <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                                  <div className="card-body">
                                                  <TableDatas>
                                                  <StyledTable className='inner-table'>
                                                      <StyledTbody>
                                                      { notes[1].length > 0 && notes[1].map((data, i) => {
                                                          return(
                                                          <StyledTr className='cursor-pointer' key={i} onClick={() => handleNotesClick(data.asset)}>
                                                            <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                                {`${data.groupid}.${data.subgroupid} ${data.title}`}
                                                                <small className='duration'>{data.size}</small>
                                                              </StyledTd>
                                                          </StyledTr>
                                                          )
                                                      })}
                                                  </StyledTbody>
                                                  </StyledTable>
                                                  </TableDatas>
                                                  </div>
                                              </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              }
                            </div>
                            
                            <div className="tab-pane fade" id="custom-tabs-four-podcasts" role="tabpanel" aria-labelledby="custom-tabs-four-podcasts-tab">
                            {Object.entries(podcastsData).length > 0 &&
                                <div className="box-group" id="accordion">
                                    <TableDatas>
                                      <StyledTable className='inner-table'>
                                        <StyledTbody>
                                          { Object.entries(podcastsData).map((podcast, index) => {
                                              return(
                                              <StyledTr className='cursor-pointer' key={index}  onClick={() => handlePodcastClick(podcast[1])}>
                                                  <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                    {`${podcast[1].groupid}.${podcast[1].subgroupid} ${podcast[1].title}`}
                                                    <small className='duration'>{podcast[1].duration}</small>
                                                  </StyledTd>
                                              </StyledTr>
                                              )
                                          })}
                                      </StyledTbody>
                                    </StyledTable>
                                  </TableDatas>
                                </div>
                              }
                            </div>

                            <div className="tab-pane fade" id="custom-tabs-four-testseries" role="tabpanel" aria-labelledby="custom-tabs-four-testseries-tab">
                            { Object.entries(testseriesData).map((testseries, index) => {
                              console.log(testseries)
                                      return (
                                        <div key={index} className="card mb-15">
                                          <div className="card-header">
                                                  <h4 className="card-title w-100">
                                                  <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>{testseries[1].title}</a>
                                                  </h4>
                                              </div>
                                              <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                                  <div className="card-body">
                                                  <div className='float-right mb-20'>
                                                    <a href={`${baseURL()}files/system?file=${testseries[1].asset}`}>
                                                      <button type="button" className="btn btn-warning top-icon brand-secondary-btn"><i className="fas fa-download" /> Download Test Series</button>
                                                    </a>
                                                    <button onClick={()=>handleUploadEvent(testseries[1])} type="button" className="btn btn-info top-icon brand-primary-btn"><i className="fas fa-upload" /> Upload Answer Sheet</button>
                                                  </div>
                                                  <TableDatas>
                                                  <StyledTable className='inner-table'>
                                                    <StyledThead>
                                                      <StyledTh>Summary</StyledTh>
                                                      <StyledTh>Uploaded By</StyledTh>
                                                      <StyledTh>Date</StyledTh>
                                                      <StyledTh>Action</StyledTh>
                                                    </StyledThead>
                                                    <StyledTbody>
                                                  { testseries[1].attachments.length > 0 && testseries[1].attachments.map((atta, index) => {
                                                    return(
                                                      <StyledTr key={index}>
                                                        <StyledTd>{atta.summary}</StyledTd>
                                                        <StyledTd>{atta.uploadedby}</StyledTd>
                                                        <StyledTd>{formatDate(atta.dt,'DD-MM-YYYY')}</StyledTd>
                                                        <StyledTd>
                                                          <ul className='action-links'>
                                                              <li>
                                                                <a href={`${baseURL()}files/system?file=${atta.asset}`}>
                                                                  <i className='fas fa-download'/>
                                                                </a>
                                                                </li>
                                                                { atta.owner_id === user.user_id &&
                                                                  <li onClick={()=>handleDeleteAttachment(testseries[1].tutor_id,atta.id)}><i className='fas fa-trash'/></li>
                                                                }
                                                          </ul>
                                                          </StyledTd>
                                                      </StyledTr>
                                                    )
                                                  })}
                                                  </StyledTbody>
                                                  </StyledTable>
                                                  </TableDatas>
                                                  </div>
                                              </div>
                                        </div>
                                      )
                                    })
                                  }
                            </div>

                            <div className="tab-pane fade" id="custom-tabs-four-others" role="tabpanel" aria-labelledby="custom-tabs-four-others-tab">
                            {Object.entries(othersData).length > 0 &&
                                <div className="box-group" id="accordion">
                                    <TableDatas>
                                      <StyledTable className='inner-table'>
                                        <StyledTbody>
                                          { Object.entries(othersData).map((other, index) => {
                                              return(
                                              <StyledTr className='cursor-pointer' key={index}  onClick={() => handleOthersClick(other[1].asset)}>
                                                  <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                    {other[1].title}
                                                    <small className='duration'>{other[1].size}</small>
                                                  </StyledTd>
                                              </StyledTr>
                                              )
                                          })}
                                      </StyledTbody>
                                    </StyledTable>
                                  </TableDatas>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                  </div>
                }                  
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
      <Modal
          isOpen={isModalOpen}
          testid="modal:select-order-type"
          width={1000}
          withCloseIcon={true}
          onClose={() => setIsModalOpen(false)}
          renderContent={modal => (
            <div className='card mb-0'>
                  {getModalContent()}
            </div> 
           )}
      />

        <Modal
          isOpen={showTutorDetails}
          testid="modal:select-order-type"
          width={500}
          withCloseIcon={true}
          onClose={() => setShowTutorDetails(false)}
          renderContent={modal => ( 
            <div className="card card-widget widget-user mb-0">
              <div className="widget-user-header bg-warning">
                <h3 className="widget-user-username">{selectedTutor.display_name}</h3>
              </div>
              <div className="widget-user-image">

                <img className="img-circle elevation-2" src={`${baseURL()}files/user/${user.user_id}?file=${selectedTutor.avatar}`} alt={selectedTutor.name} />

              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="border-right">
                    <div className="description-block">
                      <p>{selectedTutor.data.profile.summary}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           )}
      />

        <Modal
          isOpen={isTestseriesUploadModalOpen}
          testid="modal:select-order-type"
          width={700}
          withCloseIcon={true}
          onClose={() => setIsTestseriesUploadModalOpen(false)}
          renderContent={modal => (
            <Formik
            initialValues={
              { 
                file: '',
                summary: ''
              }
            }
            validationSchema={TestseriesUploadSchema}

            onSubmit={ async (values) => {
              
              const item = {
                testseries_id: clickedTestseries.id, 
                user_id: user.user_id, 
                summary: clickedTestseries.title+' Answer sheet uploaded by '+ user.displayname
              }
              
              const formData = new FormData();
              formData.append('file', selectedFile);
              formData.append('item', JSON.stringify(item));
              setIsLoading(true);

              api.post('user/v1/testseries/attachment',formData).then(function (response) {
                addToast('File uploaded successfully.', { appearance: 'success', autoDismiss: true});
                setIsLoading(false);
                handleTutorClick(selectedTutor)
                setIsTestseriesUploadModalOpen(false)
              }).catch(error => {
                setIsLoading(false);
                addToast(error.message, { appearance: 'error',autoDismiss: true });
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="card mb-0">
                <div className="card-body">
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">File</label>
                      <input type="file"
                      name='file'
                      className="form-control"
                      onChange={handleFileUpload}
                      accept=".pdf"
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Summary</label>
                      <textarea className="form-control"
                      name='summary'
                      id='summary'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows="3" placeholder="Enter ..."></textarea>
                      <ErrorMessage name="summary" component="div" className='mandatory_error' />
                    </div>
                  </div>
              </div>
              </div>
              <div className="card-footer">
                <div className='row'>
                  <div className='offset-md-3 col-md-3'>
                      <button type="button" onClick={() => history.push('/member-view')} className="btn btn-danger btn-block brand-secondary-btn">Cancel</button>
                  </div>
                  <div className='col-md-3'>
                      <button type="button" onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary btn-block brand-primary-btn">{isLoading ? 'Loading...' : 'Upload'}</button>
                  </div>
                </div>
            </div>
            </div>
          </Form>
          )}
        </Formik>
          )}
      />

<Modal
        isOpen={isNotesModalOpen}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setIsNotesModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                 <Formik
                  initialValues={
                    { 
                      note_content: ''
                    }
                  }
                  validationSchema={NotesSchema}

                  onSubmit={ async (values) => {
                    
                    setLoading(true);
                    api.post('user/v1/notes',{
                      title: selectedLecture.title,
                      content: values.note_content,
                      lecture: {
                        tutor_id: selectedLecture.tutor_id,
                        subject_id: selectedLecture.subject_id, 
                        lecture_id: selectedLecture.id
                      }
                    }).then(function (response) {
                      setLoading(false);
                      if(response.status==200)
                      {
                      setIsNotesModalOpen(false)
                      addToast('Notes added successfully', { appearance: 'success', autoDismiss: true })
                      }
                    }).catch(error => {
                      setLoading(false);
                    });
                  
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                       <div className="card card-primary m-0">
                        <div className="card-header">
                          <h3 className="card-title">Add Note</h3>
                        </div>
                          <div className="card-body">
                            <div className="form-group">
                            <textarea className="form-control"
                                  name='note_content'
                                  id='note_content'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              rows="3"></textarea>
                              <ErrorMessage name="note_content" component="div" className='mandatory_error' />
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Save'}</button>
                          </div>
                      </div>
                    </Form>
                )}
              </Formik>
          </div> 
          )}
      />

      <Modal
        isOpen={isBookmarkModalOpen}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setIsBookmarkModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                 <Formik
                  initialValues={
                    { 
                      content: ''
                    }
                  }
                  
                  onSubmit={ async (values) => {
                    
                    setLoading(true);
                    api.post('user/v1/bookmarks',{
                      title: selectedLecture.title,
                      start_time: 0,
                      end_time: 860,
                      lecture: {
                        tutor_id: selectedLecture.tutor_id,
                        subject_id: selectedLecture.subject_id, 
                        lecture_id: selectedLecture.id
                      }
                    }).then(function (response) {
                      setLoading(false);
                      if(response.status==200)
                      {
                      setIsNotesModalOpen(false)
                      addToast('Bookmark added successfully', { appearance: 'success', autoDismiss: true })
                      }
                    }).catch(error => {
                      setLoading(false);
                    });
                  
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                       <div className="card card-primary m-0">
                        <div className="card-header">
                          <h3 className="card-title">Add Bookmark</h3>
                        </div>
                          <div className="card-body">
                            <div className="progress">
                              <div className="progress-bar bg-primary progress-bar-striped" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
                                <span className="sr-only">40% Complete (success)</span>
                              </div>
                            </div>

                          </div>
                          <div className="card-footer">
                            <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Save'}</button>
                          </div>
                      </div>
                    </Form>
                )}
              </Formik>
          </div> 
          )}
      />

    </>)
}

export default MyCourses