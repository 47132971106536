import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string
};

const defaultProps = {
    className: undefined,
    size: '30px',
    color: '#000000'
};

const CloseIcon = ({ className, size, color }) => (
    <div className={className}>

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={size} viewBox="0 0 39 39"
            preserveAspectRatio="xMidYMid meet">

            <g fill="transparent">
                <path d="M19.0283 37.5005C28.9694 37.5005 37.0283 29.4417 37.0283 19.5005C37.0283 9.55942 28.9694 1.50055 19.0283 1.50055C9.08719 1.50055 1.02832 9.55942 1.02832 19.5005C1.02832 29.4417 9.08719 37.5005 19.0283 37.5005Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" />
                <path d="M25.0283 13.5005L13.0283 25.5005" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M25.0283 25.5005L13.0283 13.5005" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    </div>
);

CloseIcon.propTypes = propTypes;
CloseIcon.defaultProps = defaultProps;

export default CloseIcon;

