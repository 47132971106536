import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';

function TermsAndConditions (props) {
  
    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0"><u>JJ EDU TERMS AND CONDITIONS</u></h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="invoice p-3 mb-3">
              <div className="row">
                <div className='col-12'>
                <p>By downloading or using the app, these terms will automatically apply to you – you should make sure that you read them carefully before using the app. You’re not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages or make derivative versions. The app itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to JOJO Edtech Private Limited.</p>
                
                <p>JOJO Edtech Private Limited is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.</p>
                
                <p>You should be aware that there are certain things that JOJO Edtech Private Limited will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but JOJO Edtech Private Limited cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have an active internet connection.</p>
                
                <p>JOJO Edtech Private Limited cannot always take responsibility for the way you use the app, i.e., you need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, JOJO Edtech Private Limited cannot accept responsibility.</p>
                
                <p>Concerning JOJO Edtech Private Limited’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. JOJO Edtech Private Limited accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>
                
                <u><b>Changes to These Terms and Conditions</b></u>
                
                <p>This privacy statement is subject to change without notice to you, the user. Please read this statement each time you visit the app so that you will be fully informed of the terms and conditions of JOJO Edtech Private Limited.</p>
                
                <u><b>Cancellation and Refund</b></u>
                
                <p>Request for cancellation of membership and refunds should be addressed to our official email id <a href="mailto:contact@jojoedtech.com">contact@jojoedtech.com</a>. Any request should be allowed after review on case to case basis any time before the commencement of the service. After commencing the services, no refund shall be allowed.</p>
                
                <u><b>Do not use the application or website if you do not agree to the following terms and conditions:</b></u>
                
                <ol className="listing">
                  <li>All information, content, material, trademarks, trade names, and trade secrets including but not limited to the software, text, images, graphics, video, script, and audio, contained in the Application and website are the proprietary property of the Company (“Proprietary Information”). No Proprietary Information may be copied, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any way without obtaining prior written permission from the Company.</li>
                  <li>Your use of our website, application, or other service is solely for personal and non-commercial use. Any use of application, website, or content other than for personal use is prohibited. This may be subject to certain restrictions or legal actions.</li>
                  <li>In the preparation of content, application, and website every effort t was made to provide the most current, correct, and accurate information possible. Nevertheless, inadvertent errors may occur. The company disclaims any responsibility for such errors. Any feedback from users is welcomed to make the app error-free and user-friendly.</li>
                  <li>The contents on the application and website are developed on the concept covered are according to the structured curriculum prescribed for students of various courses. We do not endorse our application or website usage as a substitution for the curriculum-based education provided by the educational institutions.</li>
                  <li>Some content on a website or application may contain views or opinions. The company is not responsible for such opinions or any claim resulting from them.</li>
                  <li>Some part of the service is interactive. Hence, the company is not responsible or liable for any content uploaded by the user. We would not be responsible to verify whether such questions/answers or contents placed by any User contain infringing materials or not.</li>
                  <li>Upon registration through any means, the Company may contact you through the registered mobile number or e-mail or any other mobile number or email provided by you to enable effective provision of Services. The User expressly permits the Company to contact him/her and the student utilizing the Services.</li>
                  <li>While the Company has made efforts to train the personnel engaged in the sales and services relating to its products to enable quality control, it makes no warranties or representations regarding the quality and competence of such personnel and would not be responsible for any untypical, abnormal behavior of any such personnel. Any feedback from the User relating to the same is most welcome and Company reserves the right and discretion to take any action in this regard.</li>
                  <li>By submitting content on or through the Services (your “Material”), you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, modify, publish, display and distribute such material in any media or distribution methods (now known or later developed) and to associate your Material with you. You agree that others may use Your Material in the same way as any other content available through the Services. If you delete your user account your Material and name may remain available through the Services.</li>
                  <li>Any violation by you of the terms of this Clause may result in immediate suspension or termination of Your Accounts apart from any legal remedy that the Company can avail. In such instances, the Company may also disclose Your Account Information if required by any Governmental or legal authority. You understand that the violation of these Terms could also result in civil or criminal liability under applicable laws.</li>
                </ol>
                </div>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </div>)
}

export default TermsAndConditions