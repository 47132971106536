import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import toast from './toast';
import { objectToQueryString } from './url';
import { getStoredAuthToken, getStoredRefAuthToken, removeStoredAuthToken, storeAuthRefToken, storeAuthToken } from './authToken';
import { baseURL, generateNewToken, getToken, getRefreshToken } from './Common';
// const token = getToken();
// const refToken =  getRefreshToken()

const defaults = {
  baseURL: baseURL(),
  headers: () => ({
    'Content-Type': 'application/json' 
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const axiosInstance = axios.create({
  baseURL: baseURL(),
  headers: {
    "Content-Type": "multipart/form-data"
  },
});

axiosInstance.interceptors.request.use((config) => {
    if (getStoredAuthToken()) {
      config.headers["Authorization"] = `Bearer ${getStoredAuthToken()}`;
    }
    return config;
  },
  (error) => {
    console.log(error)
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // console.log(err.response)
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        var currentdate = new Date(); 
        var datetime = "Last Sync: " + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
                console.log(datetime)
        try {
          var apiUrl = `${baseURL()}oauth2/token/refresh-token?refresh_token_id=${getStoredRefAuthToken()}&token_id=${getStoredAuthToken()}`

          axios({
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            url: apiUrl,
            data: {}
          }).then(function (response) {
            console.log('Before '+getStoredAuthToken())
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('refresh_token', response.data.refresh_token);
            storeAuthToken(response.data.token)
            storeAuthRefToken(response.data.refresh_token)
            console.log('After '+getStoredAuthToken())
            axiosInstance.defaults.headers["Authorization"] = `Bearer ${response.data.token}`;
            return axiosInstance(originalConfig);
          }).catch(error => {
            if(error.response && error.response.status === 404)
            {
              // window.location.reload();
            }
          });          
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 410) {
        return err.response;
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);


const api = async (method, url, variables) =>
  new Promise((resolve, reject) => {
    
    axiosInstance({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(),
      params: method === 'get' ? variables : undefined,
      data: method !== 'get' ? variables : undefined,
      paramsSerializer: objectToQueryString,
    }).then(
      response => {
        resolve(response);
      },
      error => {
        if (error.response) {
          if (error.response.data.error && error.response.data.error.code === 'INVALID_TOKEN') {
            console.log('path 2')
          } else if(error.response.data.status === 401) {
            reject(error.response.data);
          } else {
            reject(error.response.data.error);
          }
        } else {
          reject(defaults.error);
        }
      },
    ).catch(error => {
      console.log(error)
    });
  });

const optimisticUpdate = async (url, { updatedFields, currentFields, setLocalData }) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
    toast.error(error);
  }
};

export default {
  get: (...args) => api('get', ...args),
  post: (...args) => api('post', ...args),
  put: (...args) => api('put', ...args),
  patch: (...args) => api('patch', ...args),
  delete: (...args) => api('delete', ...args),
  optimisticUpdate,
};