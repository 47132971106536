import React, { useEffect } from 'react';
import { Router, BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Login from './Pages/login';
import Dashboard from './Pages/dashBoard';
import PrivateRoute from './Shared/Utils/PrivateRoute';
import PublicRoute from './Shared/Utils/PublicRoute';
import { getToken } from './Shared/Utils/Common';
import Registration from './Pages/registration';
import MyCourses from './Pages/myCourses';
import BookMarks from './Pages/Bookmarks/list';
import NotesList from './Pages/Notes/list';
import NotesEdit from './Pages/Notes/edit';
import MyProfile from './Pages/myProfile';
import PaymentResponse from './Pages/paymentResponse';
import api from './Shared/Utils/api';
import Response from './Payment/response';
import PaymentConfirmation from './Pages/paymentConfirmation';
import PaymentSuccess from './Payment/paymentSuccess';
import PaymentError from './Payment/paymentError';
import PaymentFailed from './Payment/paymentFailed';
import history from './browserHistory';
import QuizExam from './Pages/quiz';
import Wallet from './Pages/wallet';
import TermsAndConditions from './Pages/termsAndConditions';
import PrivacyPolicy from './Pages/privacyPolicy';
import ForgotPassword from './Pages/forgotPassword';


function App() {
  // const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    
    if (!token) {
      return;
    }
  }, []);

  
  // if (authLoading && getToken()) {
  //   return <div className="content">Checking Authentication...</div>
  // }

  return (
    <ToastProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/payment-failed" component={PaymentFailed} />
          <Route exact path="/" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/login" component={Login} />
          <Route path="/registration" component={Registration} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/my-courses" component={MyCourses} />
          <Route path="/my-bookmarks" component={BookMarks} />
          <Route path="/my-notes" component={NotesList} />
          <Route path="/bookmarks" component={BookMarks} />
          <Route path="/notes-edit" component={NotesEdit} />
          <Route path="/my-profile" component={MyProfile} />
          <Route path="/payment-confirmation" component={PaymentConfirmation} />
          <Route path="/payment-response" component={PaymentResponse} />
          <Route path="/payment-success" component={PaymentSuccess} />
          <Route path="/payment-error" component={PaymentError} />
          <Route path="/quiz" component={QuizExam} />
          <Route path="/wallet" component={Wallet} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="*" component={Login} />
          
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
