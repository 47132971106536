import React, {useEffect, useState, useRef}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../../Shared/Components/Header';
import Menu from '../../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../../Shared/Utils/Common';
import api from '../../Shared/Utils/api';
import { Modal,DataTable } from '../../Shared/Components';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import 'shaka-player/dist/controls.css';
import shaka from 'shaka-player/dist/shaka-player.ui.js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function List (props) {
  const user = getUser();
  const history = useHistory();
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [bookmarksList, setBookmarksList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [selectedBookmark, setSelectedBookmark] = useState({})

  const [selectedLecture, setSelectedLecture] = useState({})
  const controllerRef = useRef(null);
  const [assetLoaded, setAssetLoaded] = useState(false);
  const [currentVideoTime, setCurrentVideoTime] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const token = getToken();
  
  useEffect(() => {

    if(!isApiCompleted)
    {
      setIsLoading(true)
      api.get('user/v1/bookmarks').then(response => {
        setIsApiCompleted(true)
        setIsLoading(false)
        setBookmarksList(response.data)
      }).catch(error => {
        setIsLoading(false)
      })
    }
  })

  // useEffect(() =>{
  //   if(Object.entries(selectedLecture).length > 0)
  //   {
  //     loadLectureVideoPlay()
  //   }
  // },[selectedLecture])

  useEffect(() => {
    if(!assetLoaded){
      let video = controllerRef.current;
      var player = new shaka.Player(video);
    
      player.addEventListener('error', onError)
      player.addEventListener('play', onPlay)
      
      player.getNetworkingEngine().registerRequestFilter(function(type, request) {
        if (type == shaka.net.NetworkingEngine.RequestType.MANIFEST ||
        type == shaka.net.NetworkingEngine.RequestType.SEGMENT) {
        request.headers['AUTHORIZATION'] = 'Bearer ' + token;
        }
      });
      let fullURL = `https://jjedutech.in:8443${selectedLecture.mpd_url}`

      // let fullURL = 'https://turtle-tube.appspot.com/t/t2/dash.mpd'
      // player.setPlaybackStartTime(selectedBookmark.end_time)

      player.load(fullURL, selectedBookmark.end_time).then(() => {
        console.log('Video loaded')
        setAssetLoaded(true)
      }).catch(onPlaybackError);
      if(video)
      {
        video.addEventListener('timeupdate', () => {
          setCurrentVideoTime(video.currentTime)
        });
      }
    }
  });

  const onPlaybackError = (error) => {
    console.error('Error while loading playback: code', error.code, 'object', error);
  }

  const onError = (event) => {
    console.error('Error code', event.detail.code, 'object', event.detail)
  }

  const onPlay = (e) => {
    console.log(e.duration())
  }

  const NotesSchema = Yup.object().shape({
    note_content: Yup.string()
      .min(6, 'Should be more than 6 character')
      .required('Required')    
  });

  const getModalContent = () => {
    if(Object.entries(selectedLecture).length > 0)
    {
      return (<>
        <div className='card-header'>{selectedLecture.title}</div>
        <div className='card-body'>
          <div className='col'>
            <div className="video-container">
              <video className="shaka-video" ref={controllerRef} preload="none" autoPlay={false} width="100%" height="500" controls muted></video>
              { assetLoaded && 
              <div className='custom-icons'>
                <div className='bookmark'>
                  <i className="nav-icon fas fa-bookmark" onClick={() => handleAddBookmarks()} />
                </div>
                <div className='notes'>
                  <i className="nav-icon fas fa-edit" onClick={() => handleAddNotes()} />
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        </>
      )
    }
  }

  const handleAddBookmarks = () => {
    setIsBookmarkModalOpen(true)
  }

  const handleAddNotes = () => {
    setIsNotesModalOpen(true)
  }

  const columns = React.useMemo(
  () => [      
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Date & Time',
      accessor: 'dt',
      Cell: ( {row} ) => {return (moment(row.dt).format('MMMM D, YYYY, h:mm A'))}
      
    },
    {
      Header: 'Action',
      accessor: 'actions',                 
      buttons_data: [{
        url: '',
        action: 'view',
        text: 'View',
        icon: 'fa-eye',
        parameters: {}
      },
      {
        url: '',
        action: 'delete',
        text: 'Delete',
        icon: 'fa-trash',
        parameters: {}
      }]        
    },
  ],
  []
)

const handleDeleteButton = (menudata, action) => {      
  setSelectedBookmark(menudata)
  if(action === 'view')
  {
    api.get(`tutor/v1/${menudata.lecture.tutor_id}/subject/${menudata.lecture.subject_id}/content/lecture/${menudata.lecture.lecture_id}`).then(res => {
      setSelectedLecture(res.data)
      setIsModalOpen(true)
    }).catch(err => {

    });
  }

  if(action === 'delete')
  {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure want to delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            api.delete(`user/v1/bookmarks/${menudata.id}`).then(function (response) {
              addToast('Bookmarks removed successfully', { appearance: 'success', autoDismiss: true });
              const newlist = bookmarksList.filter((ll) => { return ll.id !== menudata.id});
              setBookmarksList(newlist)
            }).catch(error => {
              addToast(error, { appearance: 'error', autoDismiss: true });
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }
}

    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">My Home</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header p-2">
                  Bookmarks
              </div>
              <div className="card-body">
                { bookmarksList && bookmarksList.length > 0 &&
                  <DataTable
                    data={bookmarksList}
                    columns={columns}
                    search={true}
                    isLoading={isLoading}
                    columnFilter={false}
                    rowCountIncrease={true}
                    actionButton={false}
                    bottomPagination={true}
                    onEditButtonClick={handleDeleteButton}
                    rowSelect={false}
                  />
                }
                { bookmarksList && bookmarksList.length === 0 &&
                <p className='text-center'>No records found</p>
                }
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>

      <Modal
        isOpen={isModalOpen}
        testid="modal:select-order-type"
        width={1000}
        withCloseIcon={true}
        onClose={() => setIsModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                {getModalContent()}
          </div> 
          )}
      />

<Modal
        isOpen={isNotesModalOpen}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setIsNotesModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                 <Formik
                  initialValues={
                    { 
                      note_content: ''
                    }
                  }
                  validationSchema={NotesSchema}

                  onSubmit={ async (values) => {
                    
                    setLoading(true);
                    api.post('user/v1/notes',{
                      title: selectedLecture.title,
                      content: values.note_content,
                      lecture: {
                        tutor_id: selectedLecture.tutor_id,
                        subject_id: selectedLecture.subject_id, 
                        lecture_id: selectedLecture.id
                      }
                    }).then(function (response) {
                      setLoading(false);
                      if(response.status==200)
                      {
                      setIsNotesModalOpen(false)
                      addToast('Notes added successfully', { appearance: 'success', autoDismiss: true })
                      }
                    }).catch(error => {
                      setLoading(false);
                    });
                  
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                       <div className="card card-primary m-0">
                        <div className="card-header">
                          <h3 className="card-title">Add Note</h3>
                        </div>
                          <div className="card-body">
                            <div className="form-group">
                            <textarea className="form-control"
                                  name='note_content'
                                  id='note_content'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              rows="3"></textarea>
                              <ErrorMessage name="note_content" component="div" className='mandatory_error' />
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Save'}</button>
                          </div>
                      </div>
                    </Form>
                )}
              </Formik>
          </div> 
          )}
      />

      <Modal
        isOpen={isBookmarkModalOpen}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setIsBookmarkModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                 <Formik
                  initialValues={
                    { 
                      content: ''
                    }
                  }
                  
                  onSubmit={ async (values) => {
                    
                    setLoading(true);
                    api.post('user/v1/bookmarks',{
                      title: selectedLecture.title,
                      start_time: 0,
                      end_time: Math.round(currentVideoTime),
                      lecture: {
                        tutor_id: selectedLecture.tutor_id,
                        subject_id: selectedLecture.subject_id, 
                        lecture_id: selectedLecture.id
                      }
                    }).then(function (response) {
                      setLoading(false);
                      if(response.status==200)
                      {
                      setIsBookmarkModalOpen(false)
                      addToast('Bookmark added successfully', { appearance: 'success', autoDismiss: true })
                      }
                    }).catch(error => {
                      setLoading(false);
                    });
                  
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                       <div className="card card-primary m-0">
                        <div className="card-header">
                          <h3 className="card-title">Add Bookmark</h3>
                        </div>
                          <div className="card-body">
                            <div className="progress">
                              <div className="progress-bar bg-primary progress-bar-striped" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
                                <span className="sr-only">40% Complete (success)</span>
                              </div>
                            </div>

                          </div>
                          <div className="card-footer">
                            <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Save'}</button>
                          </div>
                      </div>
                    </Form>
                )}
              </Formik>
          </div> 
          )}
      />

    </div>)
}

export default List