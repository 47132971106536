import React from 'react';
import { useTimer } from 'react-timer-hook';

const Timer = ({ expiryTimestamp, callBackFunction }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => callBackFunction() });


  return (
      <div style={{fontSize: '25px'}}>
        {/* <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span> */}
        <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span>
      </div>
  );
}

export default Timer;