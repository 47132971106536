import { createStore } from 'react-hooks-global-state';

export const { dispatch, useGlobalState } = createStore(
    (state, action) => {
        switch (action.type) {
            case 'setSubjects': return {
                ...state,
                subjects: action.subjects,
            };
            default: return state;
        }
    },
    {
        subjects: []        
    },
);