import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import moment from 'moment';

function Wallet (props) {
  const user = getUser();
  const token = getToken();
  const history = useHistory();
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [courseList, setCourseList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [walletSummary, setWalletSummary] = useState({})
  const [walletStatement, setWalletStatement] = useState({})

  useEffect(() => {

    if(!isApiCompleted)
    {
      setIsLoading(true)
      api.get(`user/v1/${user.user_id}/wallet`).then(response => {
        setIsApiCompleted(true)
        setIsLoading(false)
        setWalletSummary(response.data)
      }).catch(error => {
        setIsLoading(false)
        // addToast(error, { appearance: 'error', autoDismiss: true })
      })

      api.get(`user/v1/${user.user_id}/wallet/statement`).then(response => {
        setWalletStatement(response.data)
      }).catch(error => {
        // addToast(error, { appearance: 'error', autoDismiss: true })
      })
    }
})

    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Wallet</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-lg-6'>
                <div className="card">
                  <div className="card-header border-0 p-2">
                     Wallet Summary
                  </div>
                  <div className="card-body">
                    <div className='row'>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="info-box">
                          <span className="info-box-icon bg-info">
                            <img src='/dist/img/coin.svg'/>
                          </span>
                          <div className="info-box-content">
                            <span className="info-box-text">Opening Balance</span>
                            <span className="info-box-number">{walletSummary.opening_balance}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="info-box">
                          <span className="info-box-icon bg-info">
                            <img src='/dist/img/coin.svg'/>
                          </span>
                          <div className="info-box-content">
                            <span className="info-box-text">Coins Earned</span>
                            <span className="info-box-number">{walletSummary.earned}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="info-box">
                          <span className="info-box-icon bg-info">
                            <img src='/dist/img/coin.svg'/>
                          </span>
                          <div className="info-box-content">
                            <span className="info-box-text">Coins Redeemed</span>
                            <span className="info-box-number">{walletSummary.redeemed}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="info-box">
                          <span className="info-box-icon bg-success">
                            <img src='/dist/img/coin.svg'/>
                          </span>
                          <div className="info-box-content">
                            <span className="info-box-text">Closing Balance</span>
                            <span className="info-box-number">{walletSummary.balance}</span>
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
              <div className="card">
                  <div className="card-header border-0 p-2">
                    Wallet Statement
                  </div>
                  <div className="card-body">
                  <table className="table table-striped table-valign-middle">
                    <tbody>
                      { Object.entries(walletStatement).length > 0 && walletStatement.map((item, index) => {
                        return (
                        <tr>
                          <td>{moment(item.dt).format('MMM D, YYYY h:mm A')}</td>
                          <td>
                            { item.type === 0 &&
                            <small className="text-success mr-1">
                              <i className="fas fa-arrow-up" />
                            </small>
                            }
                            { item.type === 1 &&
                            <small className="text-danger mr-1">
                              <i className="fas fa-arrow-down" />
                            </small>
                            }
                            <img src='/dist/img/coin.svg'/>{item.earned}
                          </td>
                        </tr>
                        )
                      }) }
                      {/* <tr>
                        <td>$1,230 USD</td>
                        <td>
                          <small className="text-danger mr-1">
                            <i className="fas fa-arrow-down" />
                            3%
                          </small>
                          198 Sold
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
              
                  </div>
                </div>
              </div>
            </div>
            
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </div>)
}

export default Wallet