import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';

function PrivacyPolicy (props) {

    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0"><u>JJ Edu Privacy Policy</u></h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="invoice p-3 mb-3">
              <div className="row">
                <div className='col-12'>
                <p>JOJO Edtech Private Limited has built the JJ Edu app as a commercial app to impart learning. To honor your trust, JJ Edu adheres to ethical standards in gathering, using, and safeguarding any information you provided. If you use our service you must agree to its collection and use as-is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of personal information that we collect is used for providing and improving the service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meaning as in our Terms and Conditions.</p>
                
                <u><b>Information Collection and Use</b></u>
                
                <p>For a better experience, while using our service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us in our database for providing continuous service.</p>
                
                <p>We use the collected information to analyze trends, conduct research, administer the Application/Services and products, learn about each user's learning patterns and movements around the Application and website, and gather demographic information and usage behavior about our user base. We may use the individual data and behavior patterns combined with personal information to provide you with personalized content and better your learning objectives.</p>
                
                <p>We may disclose Information:</p>
                <ol className='listing'>
                  <li>As required by law to comply with a legal process;</li>
                  <li>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, address security or technical issues or respond to a government request;</li>
                  <li>With third-party service providers to personalize the Application and Website for a better user experience and to perform behavioral analysis.</li>
                </ol>
                
                <p>We are striving to protect your information with commercially acceptable means. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                
                <u><b>Log Data</b></u>
                
                <p>We want to inform you that whenever you use our service- in a case of an error in the app- we collect data and information (through third-party products) on your phone called log data. This log data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my service, the time and date of your use of the service, and other statistics.</p>
                
                <u><b>Cookies</b></u>
                
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. We send cookies to your browser from our website. The website does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services.</p>
                
                <p>You have the option to refuse these cookies. If you choose to refuse our cookies, you may not be able to use some of the features properly.</p>
                
                <u><b>Service Providers</b></u>
                
                <p>We may employ third-party companies and individuals due to the following reasons:</p>
                <ol className='listing'>
                  <li>To facilitate our service;</li>
                  <li>To perform additional services; or</li>
                  <li>To assist us in analyzing how our service is used.</li>
                </ol>    
                
                <p>We want to inform users that third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                
                <u><b>Links</b></u>
                <p>We may present links in a format that enables us to keep track of which links have been followed. We use this information to improve our content. We encourage our users to be aware read the privacy statements of every website that collects personally identifiable information.</p>
                
                <u><b>Changes to the Privacy policy</b></u>
                <p>This privacy statement is subject to change without notice to you. Please read this statement each time you visit the app so that you will be fully informed of the privacy policies and procedures of JOJO Edtech private limited.</p>
                
                <u><b>Contact us</b></u>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@jojoedtech.com.</p>
              

                </div>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </div>)
}

export default PrivacyPolicy