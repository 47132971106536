import React, {Fragment, useEffect, useState}  from 'react';
import { Link, useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import Footer from '../Shared/Components/Footer';
import { baseURL, getUser, getToken, getRefreshToken, appURL } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import sha512 from 'crypto-js/sha512';

function PaymentConfirmation (props) {
  
  const token = getToken();
  const user = getUser();
  const refToken = getRefreshToken();
  const history = useHistory();
  const [cart, setCart] = useState({});
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [recentActivity, setRecentActivity] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const checkoutResponse = localStorage.getItem('checkOutResponse');
  const cartId = localStorage.getItem('cartId');
  
  const [pgUrl] = useState('https://secure.payu.in/_payment')
  const [prodInfo] = useState('test description')
  const [merchant_key, setMerchantKey] = useState('')
  const [order_id, setOrderId] = useState()
  const [cart_amout, setCartAmount] = useState(1)
  const [displayname, setDisplayname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [merchant_salt, setMerchantSalt] = useState()
  const [udf1, setUdf1] = useState('')
  const [udf2, setUdf2] = useState('')
  const [udf3, setUdf3] = useState(token)
  const [udf4, setUdf4] = useState(refToken)
  const [udf5, setUdf5] = useState('jjedutech')
  const [surl] = useState(`${appURL()}/response`)
  const [hash, setHash] = useState('')

  useEffect(() => {

    if(!isApiCompleted)
    {
      api.get(`user/cart/v1/${cartId}?payment=true`).then(response => {
        setIsLoading(false)
        setCart(response.data)
        setMerchantKey(response.data.payment.merchant_key)
        setMerchantSalt(response.data.payment.merchant_salt)
        setUdf2(response.data.payment.merchant_salt)
        setOrderId(response.data.cart.order_id)
        // setCartAmount(response.data.cart.amount)
        setDisplayname(response.data.profile.displayname)
        setEmail(response.data.profile.email)
        var validPhone = response.data.profile.phone.substring(response.data.profile.phone.length - 10)
        setPhone(validPhone)
        setUdf1(validPhone)
        
        var hashString = response.data.payment.merchant_key+'|'+response.data.cart.order_id+'|'+cart_amout+'|'+prodInfo+'|'+response.data.profile.displayname+'|'+response.data.profile.email+'|'+validPhone+'|'+response.data.payment.merchant_salt+'|'+udf3+'|'+udf4+'|'+udf5+'||||||'+response.data.payment.merchant_salt
        var hash = sha512(hashString)
        setHash(hash)
        
      }).catch(error => {
        setIsLoading(false)
        addToast(error, { appearance: 'error', autoDismiss: true })
      })
      
      setIsApiCompleted(true)
    }
})

const handlePaymentRedirect = () => {
  let PaymentUrl = `https://payment.jjedutech.in:8943/process?crt_id=${cartId}&tid=${token}&rtid=${refToken}&staging=true&testing=true`
  console.log(PaymentUrl)
  
  window.open(PaymentUrl, '_blank');
  
  history.push('my-profile')
}
    return ( 
    <>
      <Header/>
      <Menu/>
      <div className="content-wrapper" style={{minHeight: '1604.44px'}}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Payment Confirmation</h1>
              </div>
              <div className="col-sm-6">
               
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="callout callout-info">
                  <h5><i className="fas fa-info" /> Note:</h5>
                    Payment related instruction will come here
                </div>
                <div className="invoice p-3 mb-3">
                  <div className="row">
                    <div className="col-12">
                      <h4>Selected items</h4>
                    </div>
                  </div>
                  <div className="row invoice-info">
                    
                  </div>
                  <div className="row">
                    <div className="col-12 table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Qty</th>
                            <th>Product</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          { Object.entries(cart).length > 0 && cart.cart.items.length > 0 && cart.cart.items.map((data, index) => {
                            return (
                            <tr key={index}>
                              <td>{data.quantity}</td>
                              <td>{data.title} <small>{data.summary}</small></td>
                              <td>{data.cost}</td>
                            </tr>
                            )
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <th>Total:</th>
                              <td>{Object.entries(cart).length > 0 && <>
                              {cart.cart.amount}
                              </> }</td>
                            </tr>
                          </tbody></table>
                      </div>
                    </div>
                  </div>
                  <div className="row no-print">
                    <div className="col-12">
                    <form action={pgUrl} id="payment_form" method='post'>
                      <input type="hidden" id="key" name="key" placeholder="Merchant Key" defaultValue={merchant_key} />
                      <input type="hidden" id="txnid" name="txnid" placeholder="Transaction ID" defaultValue={order_id} />
                      <input type="hidden" id="amount" name="amount" placeholder="Amount" defaultValue={cart_amout} />
                      <input type="hidden" id="productinfo" name="productinfo" placeholder="Product Info" defaultValue={prodInfo} />
                      <input type="hidden" id="firstname" name="firstname" placeholder="First Name" defaultValue={displayname} />
                      <input type="hidden" id="email" name="email" placeholder="Email ID" defaultValue={email} />
                      <input type="hidden" id="phone" name="phone" placeholder="Mobile/Cell Number" defaultValue={phone} />
                      <input type="hidden" id="udf1" name="udf1" defaultValue={udf1} />
                      <input type="hidden" id="udf2" name="udf2" defaultValue={udf2} />
                      <input type="hidden" id="udf3" name="udf3" defaultValue={udf3} />
                      <input type="hidden" id="udf4" name="udf4" defaultValue={udf4} />
                      <input type="hidden" id="udf5" name="udf5" defaultValue={udf5} />
                      <input type="hidden" id="surl" name="surl" defaultValue={surl} />

                      <input type="hidden" id="hash" name="hash" placeholder="Hash" defaultValue={hash} />
                      {/* <button type="submit" className="btn btn-success float-right"><i className="far fa-credit-card" /> Submit
                        Payment
                      </button> */}
                  </form>
                    <button type="button" onClick={()=>handlePaymentRedirect()} className="btn btn-warning float-right"><i className="far fa-credit-card" /> Submit Payment </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </>)
}

export default PaymentConfirmation