import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable, useRowSelect, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import { TableContainer, TableOptions, TableOptionLeftSide, TableOptionRightSide, TableSearch, TableColumnsShowHide, TableDatas, StyledTable, StyledThead, StyledTbody, StyledTr, StyledTh, StyledTd, ColumnShowHide, DropContainer, TableRowDisplay, DropButton, TablePagination, ActionLink } from './Styles';
import { ActionButton, Img, ImgBox } from './../../../Utils/commonStyle';

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TableSearch>
      <input type='text'
      className='form-control'
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder='Search...'
      />
    </TableSearch>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" className='form-check-input' ref={resolvedRef} {...rest} />
  }
)

const DataTable = ({ columns, data, search, columnFilter, isLoading, rowCountIncrease, actionButton, actionButtonText, actionButtonUrl, onAddButtonClick, onEditButtonClick, bottomPagination, multiSelect, onBulkActionSelect, rowSelect }) => {
  const history = useHistory();
  const {
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize, selectedRowIds },
    setPageSize,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    allColumns,
    getToggleHideAllColumnsProps, } = useTable({
        columns,
        data,
      },
      // hooks => {
      //   hooks.visibleColumns.push(columns => [
      //     {
      //       id: 'selection',
      //       Header: ({ getToggleAllRowsSelectedProps }) => (
      //         <div>
      //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      //         </div>
      //       ),
      //       Cell: ({ row }) => (
      //         <div>
      //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      //         </div>
      //       ),
      //     },
      //     ...columns,
      //   ])
      // },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,      
    );
  const container = React.createRef();
  const [menuopen, setMenuopen] = useState({ open: false });  
  const [bulkAction, setBulkAction] = useState('')
  const actionDropdownRef = useRef([]);
  const actionDropdownArr = []
  const handleButtonClick = () => {
    setMenuopen((state) => {
      return {
        open: !state.open,
      };
    });
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  })

  const handleClickOutside = (event) => {
    if (
      container.current &&
      !container.current.contains(event.target)
    ) {
      setMenuopen({
        open: false,
      });
      if (!event.target.classList.contains('menu-item') && 
      !event.target.classList.contains('menu-link') && 
      !event.target.parentElement.classList.contains('menu-link')) {
        actionDropdownArr.map((item, index) => {
          actionDropdownRef.current[index].closeDropdown()
        })
      }
    }    
  };
  
  const handleBulkActionSelect = (value) =>  {
    setBulkAction(value)
    onBulkActionSelect(value, selectedFlatRows)
  }
  
  return (
    <TableContainer>
      <TableOptions>
        <TableOptionLeftSide>
          {search === true &&
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          }
        </TableOptionLeftSide>
        <TableOptionRightSide>

          {columnFilter === true &&
            <TableColumnsShowHide>
              <div className="App">
                <DropContainer ref={container}>
                  <DropButton type="button" className="btn secondary-btn" onClick={handleButtonClick}>
                    ☰
                  </DropButton>
                  {menuopen.open && (
                    <div className="dropdown">
                      <ul>
                        <ColumnShowHide>
                          <li>
                            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
                            All
                          </li>
                          {allColumns.map(column => (
                            <li key={column.id}>
                              <label>
                                <inptu type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                {column.id}
                              </label>
                            </li>
                          ))}
                        </ColumnShowHide>
                      </ul>
                    </div>
                  )}
                </DropContainer>
              </div>
            </TableColumnsShowHide>
          }

          {rowCountIncrease === true &&
            <TableRowDisplay
              className="form-control secondary-btn"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </TableRowDisplay>
          }

          {actionButton === true &&
            <ActionButton
              type="button"
              className="btn"
              variant="primary"
              IsScrollToTop={true}
              onClick={() => actionButtonUrl ? history.push(actionButtonUrl) : onAddButtonClick()}
            >{actionButtonText}</ActionButton>
          }
        </TableOptionRightSide>
      </TableOptions>

      <TableDatas>
        <StyledTable {...getTableProps()}>
          <StyledThead>
            {headerGroups.map(headerGroup => (
              <StyledTr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </StyledTh>
                ))}
              </StyledTr>
            ))}

          </StyledThead>
          <StyledTbody {...getTableBodyProps()}>
            {isLoading &&
              <StyledTr>
                <StyledTd colSpan={allColumns.length}>
                  {/* <PageLoader></PageLoader> */}
                </StyledTd>
              </StyledTr>
            }
            {page.length > 0 && page.map((row, ind) => {
              prepareRow(row)

              return (
                <StyledTr {...row.getRowProps()} key={ind}>

                  {row.cells.map((cell, i) => {
                    actionDropdownArr[ind] = cell;
                    return (<StyledTd {...cell.getCellProps()} key={i}>

                      {cell.column.id === 'actions' && cell.column.buttons_data.length > 1 &&
                      <ActionLink>
                        <ul>
                        {cell.column.buttons_data.map((btn, index) => {
                            return (
                            <li key={index}>
                              { btn.icon !=='' &&
                                <i onClick={() => btn.url ? history.push({
                                    pathname: btn.url,
                                    state: { data: cell.row.original,
                                      parameters: btn.parameters }
                                }) : onEditButtonClick(cell.row.original,btn.action)} className={`fas ${ btn.action === 'publish' ? cell.row.original.published ? 'fa-eye' : 'fa-eye-slash' : btn.icon}`} />
                              }
                              { btn.icon ==='' &&
                                <button className='btn btn-default' onClick={() => history.push({
                                    pathname: btn.url,
                                    state: { data: cell.row.original,
                                      parameters: btn.parameters }
                                })} >{btn.text}</button>
                              }
                            </li>)
                        })}
                        </ul>
                      </ActionLink>
                      }
                      
                      {cell.column.id === 'actions' && cell.column.buttons_data.length === 1 &&  
                      <>
                        <ul className='action-links'>
                          <li>
                          { cell.column.buttons_data[0].icon !=='' &&
                            <i onClick={() => cell.column.buttons_data[0].url ? history.push({
                                pathname: cell.column.buttons_data[0].url,
                                state: { data: cell.row.original,
                                  parameters: cell.column.buttons_data[0].parameters }
                            }) : onEditButtonClick(cell.row.original,cell.column.buttons_data[0].action)} className={`fas ${cell.column.buttons_data[0].icon}`} />
                          }
                          { cell.column.buttons_data[0].icon ==='' &&
                                <button className='btn btn-default' onClick={() => history.push({
                                    pathname: cell.column.buttons_data[0].url,
                                    state: { data: cell.row.original,
                                      parameters: cell.column.buttons_data[0].parameters }
                                })} >{cell.column.buttons_data[0].text}</button>
                              }
                          </li>
                        </ul>
                        
                        
                      </>}

                      {cell.column.id != 'actions' && cell.column.image ? <ImgBox><Img width="100px" src={cell.row.original.image_file_path} /></ImgBox> : cell.render('Cell')}
                    </StyledTd>)
                  }
                  )}
                </StyledTr>
              )
            })}
            {page.length == 0 && <StyledTr>
                <StyledTd colSpan={allColumns.length}>No records found</StyledTd>
              </StyledTr>}
          </StyledTbody>
        </StyledTable>
        { multiSelect === true && Object.keys(selectedRowIds).length > 0 &&
        <Fragment>
          <StyledTr>
            <StyledTd><p>Selected Rows: {Object.keys(selectedRowIds).length}</p></StyledTd>
            <StyledTd>
              {/* <TableRowDisplay
                className="form-control secondary-btn"
                value={bulkAction}
                onChange={e => { handleBulkActionSelect(e.target.value)
                }}
                >
                <option key={0} value=''>Bulk action</option>  
                <option key={1} value='delete'>Delete</option>
              </TableRowDisplay> */}
              <ActionButton
                type="button"
                className="btn"
                variant="info"
                onClick={() => handleBulkActionSelect('delete')}
              >Delete</ActionButton>
            </StyledTd>
          </StyledTr>
        </Fragment>
        }
        {bottomPagination === true &&
          <TablePagination>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                className='form-control'
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
              />
            </span>{' '}

            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '}
          </TablePagination>
        }
      </TableDatas>
    </TableContainer>
  )
}


export default DataTable;
