import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL, setUserSession } from '../Shared/Utils/Common';
import {useToasts} from 'react-toast-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { storeAuthRefToken, storeAuthToken } from '../Shared/Utils/authToken';
import { PageLoader } from '../Shared/Components';
import { Link } from 'react-router-dom';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [phoneNumber, setPhoneNumber] = useState()
  const [isUserExist, setIsUserExist] = useState(false)
  const [isValidPhone, setIsValidPhone] = useState(false)
  const [isServerUnderMaintenance, setIsServerUnderMaintenance] = useState({})
  // const platform = window.navigator.platform
  
  const userExistCheck = async() => {
    await axios({
      method: 'get',
      headers: { 'Content-Type': 'application/json',
        'Authorization': ''
      },
      url: `${baseURL()}user/v1/check/phone/${phoneNumber}`,
      data: {}
    }).then(function (response) {
      if(Object.entries(response.data).length > 0)
      {
        setIsUserExist(true)
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setIsUserExist(false)
      addToast(`User with mobile: ${phoneNumber} not found`, { appearance: 'error', autoDismiss: true });
      let temp = JSON.parse(error.response['data']['message']);
      props.history.push({
        pathname: '/registration',
        state: phoneNumber,
        sessionId: temp['session-id']
      });
    });
  }

  const serverMaintenanceModeCheck = async () => {
    await axios({
      method: 'get',
      headers: { 'Content-Type': 'application/json',
        'Authorization': ''
      },
      url: `${baseURL()}server/status/v1`,
      data: {}
    }).then(function (response) {
      setIsServerUnderMaintenance(response.data)
    }).catch(error => { });
  }

  useEffect(()=>{
    serverMaintenanceModeCheck()
  },[])

  useEffect(() => {
    if(phoneNumber && phoneNumber.length > 12)
    {
      if(isValidPhoneNumber(phoneNumber))
      {
        setIsValidPhone(true)
        if(!isApiCompleted)
        {
          userExistCheck()
          setIsApiCompleted(true)
        }
      }else{
        addToast('Invalid phone number', { appearance: 'error',autoDismiss: true });
        setPhoneNumber('')
      }
    }else{
      setIsApiCompleted(false)
    }
  })

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Too Short!')
      .required('Required')
  });
  
  return (
    <div>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback" />
    <link rel="stylesheet" href="../../../plugins/fontawesome-free/css/all.min.css" />
    <link rel="stylesheet" href="../../../plugins/icheck-bootstrap/icheck-bootstrap.min.css" />
    <link rel="stylesheet" href="../../../dist/css/adminlte.min.css" />
    { isLoading && <PageLoader/> }  
    { Object.entries(isServerUnderMaintenance).length > 0 && isServerUnderMaintenance.attributes.maintenance.status === 1 && 
      <section className="content">
        <div className="error-page">
          <div className="error-content">
            <h3><i className="fas fa-exclamation-triangle text-warning" /> Under Maintenance</h3>
            <p>{isServerUnderMaintenance.attributes.maintenance.message}</p>
          </div>
        </div>
      </section>
      }
      { Object.entries(isServerUnderMaintenance).length > 0 && isServerUnderMaintenance.attributes.maintenance.status === 0 && <>
      <div className='row mt-100'>
        <div className='col-md-4 text-right'>
          <img className='login-jiji' src='/dist/img/JiJi.png' />
        </div>
        <div className='col-md-4'>
        <div className={`login-box`}>
            <Fragment>
              <div className="card mb-15">
                <div className="card-body login-card-body">
                  <div className="login-logo">
                    <a href="/">
                      <img src="/dist/img/logo.svg" alt="JJ Edu Logo" className="brand-image"/></a>
                  </div>
                                
                  <p className="login-box-msg"><span style={{fontSize: '17px'}}>Learn Apptastically</span></p>
                    <Formik
                      initialValues={
                        { 
                          password: ''
                        }
                      }
                      
                      onSubmit={ async (values) => {

                        if(phoneNumber && phoneNumber!='')
                        {
                          if(values.password !== '')
                          {
                          setLoading(true);
                          axios({
                            method: 'post',
                            headers: { 'Content-Type': 'application/json' },
                            url: `${baseURL()}oauth2/token`,
                            data: { username: phoneNumber, password: values.password }
                          }).then(function (response) {
                            setLoading(false);
                            setUserSession(response.data.token, response.data.refresh_token, response.data.user)
                            storeAuthToken(response.data.token)
                            storeAuthRefToken(response.data.refresh_token)
                            props.history.push('/dashboard')
                          }).catch(error => {
                            setLoading(false);
                            addToast('Invalid credentials', { appearance: 'error', autoDismiss: true });
                          });
                        }else{
                          addToast('Enter valid password', { appearance: 'error', autoDismiss: true });
                        }
                        }else{
                          addToast('Enter valid phone number', { appearance: 'error', autoDismiss: true });
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className='row'>
                          <div className="input-group mb-3">
                            <PhoneInput
                              placeholder="Phone number"
                              defaultCountry="IN"
                              value={phoneNumber}
                              onChange={setPhoneNumber}/>
                          </div>
                          { isUserExist && <>
                            <Field type="hidden"
                                name='password'
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Password" />
                          </> }
                          { isUserExist && 
                            <div className="input-group mb-3">
                            <Field type="password"
                                name='password'
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Password" />
                                <ErrorMessage name="password" component="div" className='mandatory_error' />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  <span className="fas fa-lock" />
                                </div>
                              </div>
                            </div>
                          }
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="icheck-primary">
                                <input type="checkbox" id="remember" />
                                <label htmlFor="remember">Keep me logged in</label>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-12">
                              <div className="text-center">
                                <br/>
                              <button type="submit" value={loading ? 'Loading...' : 'Login'} onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary brand-secondary-btn">LOGIN</button>
                              </div>
                            </div>
                          </div>
                    </Form>
                    )}
                  </Formik>
                  { isUserExist && 
                  <p className="mb-0 mt-10">
                    <Link
                    to={{
                      pathname: "/forgot-password",
                      state: { phoneNumber: phoneNumber }
                    }}
                    className="text-center">
                      Forgot Password?
                    </Link>
                  </p>
                  }
                
                  {/* <p className="mb-0">
                    <Link
                    to='/registration'
                    className="text-center">
                      Register a new membership
                    </Link>
                  </p> */}
                </div>
              </div>
            </Fragment>
        </div>
        </div>
        <div className='col-md-4 text-left'>
          <img className='login-jojo' src='/dist/img/JoJo.png' />
        </div>
      </div>
     </>
    }
  </div>
  
  );
}

export default Login;