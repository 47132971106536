import React, {useEffect, useState, useRef}  from 'react';
import { Link, useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession, groupArrayOfObjects, getDiscountPrice } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import { TableDatas, StyledTable, StyledThead, StyledTbody, StyledTr, StyledTh, StyledTd } from '../Shared/Components/Common/DataTable/Styles';
import { dispatch, useGlobalState } from '../Shared/state';
import { Modal, PageLoader } from '../Shared/Components';
import { formatDate } from '../Shared/Utils/dateTime';
import 'shaka-player/dist/controls.css';
import shaka from 'shaka-player/dist/shaka-player.ui.js';
import { PDFReader } from 'reactjs-pdf-reader';
import AudioPlayer from 'react-h5-audio-player';
import AdBanners from '../Shared/Components/Common/AdBanners';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function Dashboard (props) {
  const user = getUser();
  const token = getToken();
  const controllerRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [isTutorApiCompleted, setIsTutorApiCompleted] = useState(false)
  const [assetLoaded, setAssetLoaded] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState({})
  const [courseList, setCourseList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedLevel, setSelectedLevel] = useState({})
  const [selectedSubject, setSelectedSubject] = useState({})
  const [selectedTutor, setSelectedTutor] = useState({})
  const [selectedSubjects, setSelectedSubjects] = useState([])
  const [selectedLecture, setSelectedLecture] = useState({})

  const [showTutorDetails, setShowTutorDetails] = useState(false)
  const { addToast } = useToasts();
  const [isCartApiCompleted, setIsCartApiCompleted] = useState(false)
  const [cart, setCart] = useState({});
  const cartItems = useGlobalState('subjects')[0]
  
  const [isCheckAllChecked, setIsCheckAllChecked] = useState(false)
  const [lecturesData, setLecturesData] = useState({})
  const [lecturesDemoData, setLecturesDemoData] = useState({})
  const [podcastsData, setPodcastsData] = useState({})
  const [notesData, setNotesData] = useState({})
  const [notesDemoData, setNotesDemoData] = useState({})
  
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false)

  const [othersData, setOthersData] = useState({})
  const [testseriesData, setTestseriesData] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTestseriesUploadModalOpen, setIsTestseriesUploadModalOpen] = useState(false)
  
  const [activeAudioUrl, setActiveAudioUrl] = useState('')
  const [activeNotesUrl, setActiveNotesUrl] = useState('')
  const [activeOthersUrl, setActiveOthersUrl] = useState('')
  const [clickedTestseries, setClickedTestseries] = useState('')
  const [selectedFile, setSelectedFile] = useState({})
  const [lockLevel, setLockLevel] = useState(false)
  const [adBanners, setAdBanners] = useState()
  const [currentVideoTime, setCurrentVideoTime] = useState(0)

  const headerRef = useRef();

  useEffect(() =>{
    if(Object.entries(selectedLecture).length > 0)
    {
      loadLectureVideoPlay()
    }
  },[selectedLecture])

  const setCartItems = (items) => dispatch({
    subjects: items,
    type: 'setSubjects',
  });

  useEffect(() => {

    if(!isApiCompleted)
    {
      setIsLoading(true)
      api.get('course/v1').then(response => {
        setIsApiCompleted(true)
        setIsLoading(false)
        setCourseList(response.data)
      }).catch(error => {
        setIsLoading(false)
        if(error){
        addToast(error, { appearance: 'error', autoDismiss: true })
        }
      })
      api.get('banner/advt/v1').then(response => {
        setAdBanners(response.data)
      })
    }

    if(!isTutorApiCompleted && Object.entries(selectedTutor).length >0)
    {
      loadLectures()
      loadPodcasts()
      loadNotes()
      loadTestseries()
      loadOthers()
      setIsTutorApiCompleted(true)
      setIsLoading(false)
    }
})

let sumTotal = 0
  if(cartItems.length > 0 )
  {
    if(Object.values(selectedLevel).length >0 && cartItems.length === selectedLevel[0].subjects.length)
    {
      let discountPrice = getDiscountPrice(selectedLevel[0].pricing.mrp, selectedLevel[0].pricing.discount, 'floor')
      sumTotal = discountPrice
    }else{
      sumTotal = cartItems.reduce(function (sum, current) {
          return sum + current.price;
      }, 0);
    }
  }

const handleCheckAllLock = (level, citems) => {
  /* check and select the select all checkbox by default */
  if(citems)
  {
    let alreadyInCart = citems.findIndex(s => s.type === "LEVEL" && s.type_id === level[0].level_id)
    
    if(alreadyInCart !== -1)
    {
      setLockLevel(true)      
    }else{
      var equals = level[0].subjects.every((e, i) => { 
        let res = citems.filter(c => c.type === "SUBJECT" && c.type_id === e.subject_id)
        return res.length > 0 ? true : false
      });

      if(equals)
      {
        setLockLevel(true)
      }else{

      }
    }
  }
}

const handleCourseClick = (courseId) => {
  const selCourse = courseList.filter((c) => c.course_id === courseId)
  setSelectedCourse(selCourse)
}

const handleLevelClick = (levelId) => {
  setLockLevel(false)
  const levelSubjects = selectedCourse[0].levels.filter((lev) => lev.level_id === levelId)
  setSelectedLevel(levelSubjects)
  handleCheckAllLock(levelSubjects, cart.items)
}

const handleSubjectClick = (subjectId) => {
  const subjects = selectedLevel[0].subjects.filter((sub) => sub.subject_id === subjectId)
  setSelectedSubject(subjects)
  if(Object.values(subjects[0].data.tutors).length > 0)
  {
    handleTutorClick(Object.values(subjects[0].data.tutors)[0])
  }
}

const handleTutorClick = async (tutor) => {
  setSelectedTutor(tutor)
  setIsLoading(true)
  setIsTutorApiCompleted(false)
}

const handleBackClick = () => {

  if(selectedSubject.length > 0)
  {
    setSelectedSubject({})
    setSelectedTutor({})
  }else if(selectedLevel.length > 0)
  {
    setSelectedLevel({})
    setIsCheckAllChecked('')
    setSelectedSubjects([])
  }
  else if(selectedCourse.length > 0)
  {
    setSelectedCourse({})
  }
}

const addToCart = () => {
  setIsLoading(true)
  
  let item = '';
  if(selectedSubjects.length === selectedLevel[0].subjects.length)
  {
    item = {
      "amount":sumTotal,
      "course_id":selectedCourse[0].course_id,
      "level_id":selectedLevel[0].level_id      
    }
  }else{
    
    let subjectArray = selectedSubjects.map(sub => {
      return ({
        "amount":sub.price,
        "subject_id":sub.subjectId
      })
    })

    item = {
      "amount":sumTotal,
      "course_id":selectedCourse[0].course_id,
      "level_id":selectedLevel[0].level_id,
      "subjects":subjectArray
    }
  }

  api.post('user/cart/v1',item).then(response => {
    headerRef.current.refreshCart()
    addToast('Cart updated successfully.', { appearance: 'success', autoDismiss: true});
    setIsCartApiCompleted(false)
    setSelectedSubjects([])
    setIsLoading(false);
    setIsCheckAllChecked(false)
    handleCheckAllLock(selectedLevel, cart.items)
  }).catch(error => {
    setIsLoading(false)
    if(error){
    addToast(error.message, { appearance: 'error',autoDismiss: true });
    }
  });
}

const handleLogout = () => {
  removeUserSession();
  props.history.push('/');
}

const handleCheckAll = (event) => {

  let selectedSubjectArray = [...selectedSubjects];
  
  selectedLevel[0].subjects.map(subj => {
    let index = selectedSubjectArray.findIndex(o => o.subjectId === subj.subjectId);
    if(event.target.checked){

      let discountPrice = getDiscountPrice(subj.pricing.mrp, subj.pricing.discount, 'floor')
      let newItemArray  = {
        subjectId: subj.subject_id,
        levelId: selectedLevel[0].level_id,
        courseId: selectedCourse[0].course_id,
        subjectName: subj.name,
        levelName: selectedLevel[0].name,
        courseName: selectedCourse[0].name,
        price: discountPrice
      }
      if(index=== -1)
      {
        selectedSubjectArray.push(newItemArray)
      }
    }else{
      selectedSubjectArray.splice(index, 1);
    }
  })  
  setSelectedSubjects(selectedSubjectArray)
  setCartItems(selectedSubjectArray)
  setIsCheckAllChecked(event.target.checked)
}

const handleItemClick = (array) => {
  let selectedSubjectArray = [...selectedSubjects];
  let index = selectedSubjectArray.findIndex(o => o.subjectId === array.subjectId);
  if(index=== -1)
  {
    selectedSubjectArray.push(array)
    
  }else{
    selectedSubjectArray.splice(index, 1);
  }

  setSelectedSubjects(selectedSubjectArray)
  setCartItems(selectedSubjectArray)
  if(selectedSubjectArray.length === selectedLevel[0].subjects.length)
  {
    setIsCheckAllChecked(true)
  }else{
    setIsCheckAllChecked(false)
  }
}

const handleCartCheckout = () => {
  setIsLoading(true)
  setSelectedCourse({})
  setSelectedLevel({})
  setSelectedSubject({})
  setSelectedSubjects([])
  setCartItems({})
  setIsLoading(false)
}

const handleCartItemDelete = (cItems) => {
  setCart(cItems)
}

const onError = (event) => {
  console.error('Error code', event.detail.code, 'object', event.detail)
}


const onPlaybackError = (error) => {
  console.error('Error while loading playback: code', error.code, 'object', error);
}

async function loadLectureVideoPlay() {
  let video = controllerRef.current;
  var player = new shaka.Player(video);

  player.addEventListener('error', onError)
  player.addEventListener('play', onPlay)
  
  player.getNetworkingEngine().registerRequestFilter(function(type, request) {
    if (type == shaka.net.NetworkingEngine.RequestType.MANIFEST ||
    type == shaka.net.NetworkingEngine.RequestType.SEGMENT) {
    request.headers['AUTHORIZATION'] = 'Bearer ' + token;
    }
  });
  
  let fullURL = `https://jjedutech.in:8443${selectedLecture.mpd_url}`
  // let fullURL = 'https://turtle-tube.appspot.com/t/t2/dash.mpd'

  await player.load(fullURL).then(() => {
    setAssetLoaded(true);
  }).catch(onPlaybackError);
  
  video.addEventListener('timeupdate', () => {
    setCurrentVideoTime(video.currentTime)
    // if (video.currentTime >= 10)
    //   video.pause();
  });
  
}

const onPlay = (e) => {
  console.log(e.duration())
}

const handleDeleteAttachment = (tutor_id,attachment_id) => {

}

const loadLectures = () => {
  // published
  api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/lecture/published`).then(function (response) {
    const typeWiseLectures = groupArrayOfObjects(response.data, 'type')
    setLecturesDemoData(typeWiseLectures[0])
    const chapterWiseLectures = groupArrayOfObjects(response.data, 'groupid')
    setLecturesData(chapterWiseLectures)
  }).catch(error => {
    addToast(error.message, { appearance: 'error',autoDismiss: true });
  });
}

const loadPodcasts = () => {
  api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/podcast`).then(function (response) {
    setPodcastsData(response.data)
  }).catch(error => {
    addToast(error.message, { appearance: 'error',autoDismiss: true });
  });    
}

const loadNotes = () => {
  api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/notes`).then(function (response) {
      const typeWiseNotes = groupArrayOfObjects(response.data, 'type')
      setNotesDemoData(typeWiseNotes[0])
      const chapterWiseNotes = groupArrayOfObjects(response.data, 'groupid')
      setNotesData(chapterWiseNotes)
  }).catch(error => {
    addToast(error.message, { appearance: 'error',autoDismiss: true });
  });
}

const loadTestseries = () => {
  api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/testseries`).then(function (response) {
      setTestseriesData(response.data)
  }).catch(error => {
    addToast(error.message, { appearance: 'error',autoDismiss: true });
  });
}

const loadOthers = () => {
  api.get(`tutor/v1/${selectedTutor.tutor_id}/subject/${selectedSubject[0].subject_id}/content/others`).then(function (response) {
      setOthersData(response.data)
  }).catch(error => {
    addToast(error.message, { appearance: 'error',autoDismiss: true });
  });
}

const handleLectureClick = (lectData) => {
  setSelectedLecture(lectData)
  setIsModalOpen(true)
  setActiveAudioUrl('')
  setActiveNotesUrl('')
  setActiveOthersUrl('')
}

const handlePodcastClick = (podcast) => {
  setIsModalOpen(true)
  setSelectedPodcast(podcast)
  let fullURL = `${baseURL()}files/system?file=${podcast.asset}`
  setActiveAudioUrl(fullURL)
  setSelectedLecture({})
  setActiveNotesUrl('')
  setActiveOthersUrl('')
}

const handleNotesClick = (url) => {
  let activeURL = `${baseURL()}files/system?file=${url}`
  setActiveNotesUrl(activeURL)
  setSelectedLecture({})
  setActiveAudioUrl('')
  setActiveOthersUrl('')
  setIsModalOpen(true)
}

const handleOthersClick = (url) => {
  setIsModalOpen(true)
  let activeURL = `${baseURL()}files/system?file=${url}`
  setActiveOthersUrl(activeURL)
  setSelectedLecture({})
  setActiveAudioUrl('')
  setActiveNotesUrl('')
}

const handleUploadEvent = (testseries) => {
  setClickedTestseries(testseries)
  setIsTestseriesUploadModalOpen(true)
}

const handleAddNotes = () => {
  setIsNotesModalOpen(true)
}

const handleAddBookmarks = () => {
  setIsBookmarkModalOpen(true)
}

const getModalContent = () => {
  if(Object.entries(selectedLecture).length > 0)
  {
    return (<>
      <div className='card-header'>{selectedLecture.title}</div>
      <div className='card-body'>
        <div className='col'>
          <div className="video-container">
            <video className="shaka-video" ref={controllerRef} preload="none" autoPlay={false} width="100%" height="500" controls muted></video>
            { assetLoaded && 
            <div className='custom-icons'>
              <div className='bookmark'>
                <i className="nav-icon fas fa-bookmark" onClick={() => handleAddBookmarks()} />
              </div>
              <div className='notes'>
                <i className="nav-icon fas fa-edit" onClick={() => handleAddNotes()} />
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      </>
    )
  }

  if(activeAudioUrl !== '')
  {
    return (<>
      <div className='card-header'>
        {selectedPodcast.title}
      </div>
      <div className='card-body'>
        <div className='col'>
        <AudioPlayer
          src={`${baseURL()}files/system?file=${selectedPodcast.asset}`}
          volume={0.5}
          // Try other props!
        />
        </div>
      </div>
      </>
    )    
  }

  if(activeNotesUrl !== '')
  {
      return <PDFReader url={activeNotesUrl} />
  }

  if(activeOthersUrl !== '')
  {
      return <PDFReader url={activeOthersUrl} />
  }  
}

const handleCartItems = (citems) => {
  setCart(citems)
  handleCheckAllLock(selectedLevel, citems)
}

const NotesSchema = Yup.object().shape({
  note_content: Yup.string()
    .min(6, 'Should be more than 6 character')
    .required('Required')    
});

    return ( 
    <>
      <Header ref={headerRef}
      handleCartItemDelete={(cItems) => handleCartItemDelete(cItems)}
      handlePageLoader={(status) => setIsLoading(status)}
      handleCartItems={(citems) => handleCartItems(citems)}
      handleCartCheckout={() => handleCartCheckout()}
       />
      <Menu/>
      { isLoading && <PageLoader/> }  
      { !isLoading &&
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <Link to='/quiz'
              className='text-center'>
                <img className="quiz-banner" src="../../dist/img/quiz-banner.jpg" alt="Quiz banner" />
              </Link>
              <div className="col-sm-6">
                <h1 className="m-0"></h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header p-2">
                { Object.values(selectedCourse).length === 0 && <h3 className='card-title'>Courses</h3>}
                { Object.values(selectedCourse).length > 0 && <>
                    <button onClick={() => handleBackClick()} type="button" className="btn btn-default back-btn"><i className="fas fa-arrow-left" /></button>
                    <ul className='inner-breadcrump'>
                      { Object.values(selectedCourse).length > 0 &&
                      <li><a>{selectedCourse[0].name}</a></li>
                      }
                      { Object.values(selectedLevel).length > 0 &&
                        <li><a>{ selectedLevel[0].name}</a></li>
                      }
                      { Object.values(selectedSubject).length > 0 &&
                        <li><a>{ selectedSubject[0].name}</a></li>
                      }
                    </ul>
                  </>
                }
              </div>
              <div className="card-body">
                { Object.values(selectedCourse).length === 0 && <>
                  <div className='row'>  
                    { courseList && Object.values(courseList).map((course, index) => {
                      return (
                        <div key={index} onClick={()=>handleCourseClick(course.course_id)} className="col-md-4 col-sm-6 col-12 course-box">
                          <div className="info-box">
                            <span className="info-box-icon">
                            <img className="w-60 h-60" src={`${baseURL()}files/system?file=${course.icon}`} /></span>
                            <div className="info-box-content">
                              <span className="info-box-number">{course.name}</span>
                              <span className="info-box-text">{course.description}</span>
                            </div>
                            {/* /.info-box-content */}
                          </div>
                          {/* /.info-box */}
                        </div>
                        )
                      })
                    }
                  </div>
                  <AdBanners adBanners={adBanners} />
                  </>
                }

                { Object.values(selectedLevel).length === 0 &&  
                  <div className='row'>
                  { Object.values(selectedCourse).length > 0 &&  Object.values(selectedCourse[0].levels).map((level, index) => {
                    return(
                      <div key={index} onClick={()=>handleLevelClick(level.level_id)} className="col-md-4 col-sm-6 col-12 course-box">
                        <div className="info-box">
                          <span className="info-box-icon">
                            <i className="far fa-flag" />
                          </span>
                          <div className="info-box-content">
                            <span className="info-box-number">{level.name}</span>
                            {/* <div>
                              <small className='strikethrough'>₹{level.pricing.mrp}</small> 
                              <span className='ml-2 brand-primary-text'>₹{getDiscountPrice(level.pricing.mrp, level.pricing.discount, 'floor')}</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      )
                    }) }
                  </div>
                }

                { Object.values(selectedCourse).length > 0 && Object.values(selectedSubject).length === 0 && Object.values(selectedLevel).length > 0 && 
                  <div className='row'>
                    <div className="col-md-12 col-sm-12 col-12 mb-15">
                      <table className="table table-striped projects">
                        <thead>
                          <tr>
                            <th style={{width: '1%'}}>
                              <div className="mailbox-controls">
                                {/* { lockLevel && <>
                                <i className="fas fa-check success"></i>
                                </> } */}                                
                                <input
                                  type="checkbox"
                                  className='btn btn-default btn-sm checkbox-toggle'
                                  checked={isCheckAllChecked}
                                  defaultChecked={isCheckAllChecked}
                                  onClick={(event) => handleCheckAll(event)}
                                  value="checkedall"
                                />
                              </div>
                            </th>
                            <th style={{width: '90%'}}>
                            {selectedLevel[0].name}
                            </th>
                            <th>
                              <small className='strikethrough pr-5'>₹{selectedLevel[0].pricing.mrp}</small> 
                              <span className='brand-primary-text'>₹{getDiscountPrice(selectedLevel[0].pricing.mrp, selectedLevel[0].pricing.discount, 'floor')}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        { Object.values(selectedLevel[0].subjects).map((subject, index) => {
                          let discountPrice = getDiscountPrice(subject.pricing.mrp, subject.pricing.discount, 'floor');

                          let newItemArray  = {
                            subjectId: subject.subject_id,
                            levelId: selectedLevel[0].level_id,
                            courseId: selectedCourse[0].course_id,
                            subjectName: subject.name,
                            levelName: selectedLevel[0].name,
                            courseName: selectedCourse[0].name,
                            price: discountPrice
                          }
                          
                          let subjectChk = selectedSubjects.findIndex(s => s.subjectId === subject.subject_id) 
                          let alreadyInCart = -1
                          if(cart.items)
                          {
                            alreadyInCart = cart.items.findIndex(s => s.type === "LEVEL" && s.type_id === selectedLevel[0].level_id)
                            
                            if(alreadyInCart === -1)
                            {
                              alreadyInCart = cart.items.findIndex(s => s.type === "SUBJECT" && s.type_id === subject.subject_id)
                            }
                          }
                          
                        return(
                          <tr key={index} >
                            <td>
                              <div className="icheck-primary">
                              { alreadyInCart !== -1 && <>
                                <i className="fas fa-check success"></i>
                                </>
                              }
                              { alreadyInCart === -1 && <>
                                <input
                                  type="checkbox"
                                  id={`check${index}`}
                                  checked={subjectChk== -1 ? false : true}
                                  value={subject.subject_id}
                                  onClick={()=>{handleItemClick(newItemArray)}}
                                />
                                <label htmlFor={`check${index}`} />
                                </>
                              }
                              </div>
                            </td>
                            <td>
                              <a className='course-box' onClick={()=>handleSubjectClick(subject.subject_id)}>{subject.name}</a>
                            </td>
                            <td className="project_progress text-right"><span className='brand-primary-text'>₹{discountPrice}</span>
                            <small className='strikethrough d-block'>₹{subject.pricing.mrp}</small></td>
                          </tr>
                          )
                        }) }
                        </tbody>
                      </table>
                      {selectedSubjects.length > 0 && 
                        <div className='row'>
                          <div className='col-sm-3'>
                            <button type="button" onClick={()=>addToCart()} className="btn btn-block btn-primary btn-sm">Add to cart ₹{sumTotal}</button>
                          </div>
                          
                        </div>
                      }
                    </div>
                  </div>
                }

                { Object.values(selectedSubject).length > 0 && selectedSubject[0].data && Object.values(selectedSubject[0].data.tutors).length > 0 && 
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 mb-15">
                      <ul className="users-list clearfix">
                        { Object.values(selectedSubject[0].data.tutors).map((tutor, index) => {
                          return(
                              <li className={selectedTutor.tutor_id === tutor.tutor_id ? 'active' : '' } key={index} onClick={()=> { return selectedTutor.tutor_id === tutor.tutor_id ? undefined : handleTutorClick(tutor) }}>
                                <img src={`${baseURL()}files/system?file=${tutor.avatar}`} alt={tutor.display_name} className="img-circle elevation-2" />
                                { selectedTutor.tutor_id === tutor.tutor_id && 
                                  <i className='fa fa-question-circle view-tutor-details' onClick={() => {setShowTutorDetails(true)}}></i>
                                }
                                <a className="users-list-name" title={tutor.data.profile.summary} href="#">{tutor.display_name}</a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                }

                <div className='row'>
                  <div className="col-12 col-sm-12">
                    { isLoading === false && Object.values(selectedTutor).length > 0 &&
                    <div className="card card-primary card-outline card-outline-tabs">
                      <div className="card-header p-0 border-bottom-0">
                        <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                          <li className="nav-item">
                            <a className='nav-link active' id="custom-tabs-four-lectures-tab" data-toggle="pill" href="#custom-tabs-four-lectures" role="tab" aria-controls="custom-tabs-four-lectures" aria-selected="true">Lectures</a>
                          </li>
                          <li className="nav-item">
                            <a className='nav-link' id="custom-tabs-four-notes-tab" data-toggle="pill" href="#custom-tabs-four-notes" role="tab" aria-controls="custom-tabs-four-notes" aria-selected="true" >Notes</a>
                          </li>
                          <li className="nav-item">
                            <a className='nav-link'  id="custom-tabs-four-podcasts-tab" data-toggle="pill" href="#custom-tabs-four-podcasts" role="tab" aria-controls="custom-tabs-four-podcasts" aria-selected="true" >Podcasts</a>
                          </li>
                          <li className="nav-item">
                            <a className='nav-link'  id="custom-tabs-four-testseries-tab" data-toggle="pill" href="#custom-tabs-four-testseries" role="tab" aria-controls="custom-tabs-four-testseries" aria-selected="true" >Testseries</a>
                          </li>
                          <li className="nav-item">
                            <a className='nav-link' id="custom-tabs-four-others-tab" data-toggle="pill" href="#custom-tabs-four-others" role="tab" aria-controls="custom-tabs-four-others" aria-selected="false" >Others</a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                          <div className="tab-pane fade show active" id="custom-tabs-four-lectures" role="tabpanel" aria-labelledby="custom-tabs-four-lectures-tab">
                            {Object.entries(lecturesData).length > 0 &&
                              <div className="box-group" id="accordion">
                                {/* Demo Lectures */}
                                { Object.entries(lecturesDemoData).length > 0 && 
                                    <div className="card  mb-15">
                                      <div className="card-header">
                                            <h4 className="card-title w-100">
                                            <a className="d-block w-100" data-toggle="collapse" href={`#collapse999`}>Demo</a>
                                            </h4>
                                          </div>
                                          <div id={`collapse999`} className="collapse" data-parent="#accordion">
                                              <div className="card-body">
                                              <TableDatas>
                                              <StyledTable className='inner-table'>
                                                  <StyledTbody>
                                                  { lecturesDemoData.map((data, i) => {
                                                      return(
                                                      <StyledTr className='cursor-pointer' key={i} onClick={() => handleLectureClick(data)}>
                                                          <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                            {`${data.groupid}.${data.subgroupid}.${data.sequenceid} ${data.title}`}
                                                            <small className='duration'>{data.duration}</small>
                                                          </StyledTd>
                                                      </StyledTr>
                                                      )
                                                  })}
                                              </StyledTbody>
                                              </StyledTable>
                                              </TableDatas>
                                              </div>
                                          </div>
                                    </div>
                                }

                                { Object.entries(lecturesData).map((lecture, index) => {
                                    return (
                                      <div key={index} className="card mb-15">
                                        <div className="card-header">
                                                <h4 className="card-title w-100">
                                                <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>Chapter {lecture[0]}</a>
                                                </h4>
                                            </div>
                                            <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                <TableDatas>
                                                <StyledTable className='inner-table'>
                                                    <StyledTbody>
                                                    { lecture[1].length > 0 && lecture[1].map((data, i) => {
                                                        return(
                                                        <StyledTr className='cursor-pointer' key={i}>
                                                            <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                              <div className='main-row'>
                                                                <div className='main-col'>
                                                                  <i className='fa fa-lock'></i>
                                                                </div>
                                                                <div className='main-col'>
                                                                  {`${data.groupid}.${data.subgroupid}.${data.sequenceid} ${data.title}`}
                                                                  <small className='duration'>{data.duration}</small>
                                                                </div>
                                                              </div>
                                                            </StyledTd>
                                                        </StyledTr>
                                                        )
                                                    })}
                                                </StyledTbody>
                                                </StyledTable>
                                                </TableDatas>
                                                </div>
                                            </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                          </div>

                          <div className="tab-pane fade" id="custom-tabs-four-notes" role="tabpanel" aria-labelledby="custom-tabs-four-notes-tab">
                          {Object.entries(notesData).length > 0 &&
                              <div className="box-group" id="accordion">
                                { Object.entries(notesDemoData).length > 0 && 
                                  <div className="card  mb-15">
                                    <div className="card-header">
                                            <h4 className="card-title w-100">
                                            <a className="d-block w-100" data-toggle="collapse" href={`#collapse999`}>Demo</a>
                                            </h4>
                                        </div>
                                        <div id={`collapse999`} className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                            <TableDatas>
                                            <StyledTable className='inner-table'>
                                                <StyledTbody>
                                                { notesDemoData.map((data, i) => {
                                                    return(
                                                    <StyledTr className='cursor-pointer' key={i} onClick={() => handleNotesClick(data.asset)}>
                                                      <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                          {`${data.groupid}.${data.subgroupid} ${data.title}`}
                                                          <small className='duration'>{data.size}</small>
                                                        </StyledTd>
                                                    </StyledTr>
                                                    )
                                                })}
                                            </StyledTbody>
                                            </StyledTable>
                                            </TableDatas>
                                            </div>
                                        </div>
                                  </div>
                                }
                                { Object.entries(notesData).map((notes, index) => {
                                    return (
                                      <div key={index} className="card mb-15">
                                        <div className="card-header">
                                                <h4 className="card-title w-100">
                                                <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>Chapter {notes[0]}</a>
                                                </h4>
                                            </div>
                                            <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                <TableDatas>
                                                <StyledTable className='inner-table'>
                                                    <StyledTbody>
                                                    { notes[1].length > 0 && notes[1].map((data, i) => {
                                                        return(
                                                        <StyledTr className='cursor-pointer' key={i}>
                                                          <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                              <div className='main-row'>
                                                                <div className='main-col'>
                                                                  <i className='fa fa-lock'></i>
                                                                </div>
                                                                <div className='main-col'>
                                                                {`${data.groupid}.${data.subgroupid} ${data.title}`}
                                                                <small className='duration'>{data.size}</small>
                                                                </div>
                                                              </div>
                                                              
                                                            </StyledTd>
                                                        </StyledTr>
                                                        )
                                                    })}
                                                </StyledTbody>
                                                </StyledTable>
                                                </TableDatas>
                                                </div>
                                            </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                          </div>
                          
                          <div className="tab-pane fade" id="custom-tabs-four-podcasts" role="tabpanel" aria-labelledby="custom-tabs-four-podcasts-tab">
                          {Object.entries(podcastsData).length > 0 &&
                              <div className="box-group" id="accordion">
                                  <TableDatas>
                                    <StyledTable className='inner-table'>
                                      <StyledTbody>
                                        { Object.entries(podcastsData).map((podcast, index) => {
                                            return(
                                            <StyledTr className='cursor-pointer' key={index}  onClick={() => handlePodcastClick(podcast[1])}>
                                                <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                  {`${podcast[1].groupid}.${podcast[1].subgroupid} ${podcast[1].title}`}
                                                  <small className='duration'>{podcast[1].duration}</small>
                                                </StyledTd>
                                            </StyledTr>
                                            )
                                        })}
                                    </StyledTbody>
                                  </StyledTable>
                                </TableDatas>
                              </div>
                            }
                          </div>

                          <div className="tab-pane fade" id="custom-tabs-four-testseries" role="tabpanel" aria-labelledby="custom-tabs-four-testseries-tab">
                          { Object.entries(testseriesData).map((testseries, index) => {
                            
                                    return (
                                      <div key={index} className="card mb-15">
                                        <div className="card-header">
                                                <h4 className="card-title w-100">
                                                <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>{testseries[1].title}</a>
                                                </h4>
                                            </div>
                                            <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                <div className='float-right mb-20'>
                                                  <a href={`${baseURL()}files/system?file=${testseries[1].asset}`}>
                                                    <button type="button" className="btn btn-warning top-icon brand-secondary-btn"><i className="fas fa-download" /> Download Test Series</button>
                                                  </a>
                                                  <button onClick={()=>handleUploadEvent(testseries[1])} type="button" className="btn btn-info top-icon brand-primary-btn"><i className="fas fa-upload" /> Upload Answer Sheet</button>
                                                </div>
                                                <TableDatas>
                                                <StyledTable className='inner-table'>
                                                  <StyledThead>
                                                    <StyledTr>
                                                    <StyledTh>Summary</StyledTh>
                                                    <StyledTh>Uploaded By</StyledTh>
                                                    <StyledTh>Date</StyledTh>
                                                    <StyledTh>Action</StyledTh>
                                                    </StyledTr>
                                                  </StyledThead>
                                                  <StyledTbody>
                                                { testseries[1].attachments.length > 0 && testseries[1].attachments.map((atta) => {
                                                  return(
                                                    <StyledTr>
                                                      <StyledTd>{atta.summary}</StyledTd>
                                                      <StyledTd>{atta.uploadedby}</StyledTd>
                                                      <StyledTd>{formatDate(atta.dt,'DD-MM-YYYY')}</StyledTd>
                                                      <StyledTd>
                                                        <ul className='action-links'>
                                                            <li>
                                                              <a href={`${baseURL()}files/system?file=${atta.asset}`}>
                                                                <i className='fas fa-download'/>
                                                              </a>
                                                              </li>
                                                              { atta.owner_id === user.user_id &&
                                                                <li onClick={()=>handleDeleteAttachment(testseries[1].tutor_id,atta.id)}><i className='fas fa-trash'/></li>
                                                              }
                                                        </ul>
                                                        </StyledTd>
                                                    </StyledTr>
                                                  )
                                                })}
                                                </StyledTbody>
                                                </StyledTable>
                                                </TableDatas>
                                                </div>
                                            </div>
                                      </div>
                                    )
                                  })
                                }
                          </div>

                          <div className="tab-pane fade" id="custom-tabs-four-others" role="tabpanel" aria-labelledby="custom-tabs-four-others-tab">
                          {Object.entries(othersData).length > 0 &&
                              <div className="box-group" id="accordion">
                                  <TableDatas>
                                    <StyledTable className='inner-table'>
                                      <StyledTbody>
                                        { Object.entries(othersData).map((other, index) => {
                                            return(
                                            <StyledTr className='cursor-pointer' key={index}  onClick={() => handleOthersClick(other[1].asset)}>
                                                <StyledTd padding='0.5rem 0.75rem 0.5rem 0.75rem'>
                                                  {other[1].title}
                                                  <small className='duration'>{other[1].size}</small>
                                                </StyledTd>
                                            </StyledTr>
                                            )
                                        })}
                                    </StyledTbody>
                                  </StyledTable>
                                </TableDatas>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </div>
      }
      <Footer/>
      <Modal
        isOpen={showTutorDetails}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setShowTutorDetails(false)}
        renderContent={modal => ( 
          <div className="card card-widget widget-user mb-0">
            <div className="widget-user-header bg-warning">
              <h3 className="widget-user-username">{selectedTutor.display_name}</h3>
            </div>
            <div className="widget-user-image">

              <img className="img-circle elevation-2" src={`${baseURL()}files/user/${user.user_id}?file=${selectedTutor.avatar}`} alt={selectedTutor.name} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="border-right">
                  <div className="description-block">
                    <p>{selectedTutor.data.profile.summary}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
      />

      <Modal
        isOpen={isModalOpen}
        testid="modal:select-order-type"
        width={1000}
        withCloseIcon={true}
        onClose={() => setIsModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                {getModalContent()}
          </div> 
          )}
      />
      
      <Modal
        isOpen={isNotesModalOpen}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setIsNotesModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                 <Formik
                  initialValues={
                    { 
                      note_content: ''
                    }
                  }
                  validationSchema={NotesSchema}

                  onSubmit={ async (values) => {
                    
                    setLoading(true);
                    api.post('user/v1/notes',{
                      title: selectedLecture.title,
                      content: values.note_content,
                      lecture: {
                        tutor_id: selectedLecture.tutor_id,
                        subject_id: selectedLecture.subject_id, 
                        lecture_id: selectedLecture.id
                      }
                    }).then(function (response) {
                      setLoading(false);
                      if(response.status==200)
                      {
                      setIsNotesModalOpen(false)
                      addToast('Notes added successfully', { appearance: 'success', autoDismiss: true })
                      }
                    }).catch(error => {
                      setLoading(false);
                    });
                  
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                       <div className="card card-primary m-0">
                        <div className="card-header">
                          <h3 className="card-title">Add Note</h3>
                        </div>
                          <div className="card-body">
                            <div className="form-group">
                            <textarea className="form-control"
                                  name='note_content'
                                  id='note_content'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              rows="3"></textarea>
                              <ErrorMessage name="note_content" component="div" className='mandatory_error' />
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Save'}</button>
                          </div>
                      </div>
                    </Form>
                )}
              </Formik>
          </div> 
          )}
      />

      <Modal
        isOpen={isBookmarkModalOpen}
        testid="modal:select-order-type"
        width={500}
        withCloseIcon={true}
        onClose={() => setIsBookmarkModalOpen(false)}
        renderContent={modal => (
          <div className='card mb-0'>
                 <Formik
                  initialValues={
                    { 
                      content: ''
                    }
                  }
                  
                  onSubmit={ async (values) => {
                    
                    setLoading(true);
                    api.post('user/v1/bookmarks',{
                      title: selectedLecture.title,
                      start_time: 0,
                      end_time: Math.round(currentVideoTime),
                      lecture: {
                        tutor_id: selectedLecture.tutor_id,
                        subject_id: selectedLecture.subject_id, 
                        lecture_id: selectedLecture.id
                      }
                    }).then(function (response) {
                      setLoading(false);
                      if(response.status==200)
                      {
                      setIsBookmarkModalOpen(false)
                      addToast('Bookmark added successfully', { appearance: 'success', autoDismiss: true })
                      }
                    }).catch(error => {
                      setLoading(false);
                    });
                  
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                       <div className="card card-primary m-0">
                        <div className="card-header">
                          <h3 className="card-title">Add Bookmark</h3>
                        </div>
                          <div className="card-body">
                            <div className="progress">
                              <div className="progress-bar bg-primary progress-bar-striped" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
                                <span className="sr-only">40% Complete (success)</span>
                              </div>
                            </div>

                          </div>
                          <div className="card-footer">
                            <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Save'}</button>
                          </div>
                      </div>
                    </Form>
                )}
              </Formik>
          </div> 
          )}
      />
    </>)
}

export default Dashboard