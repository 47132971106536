import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import api from '../../../Utils/api';
import { baseURL } from '../../../Utils/Common';

const AdBanners = (props) => {

    const {adBanners} = props
    
    return ( 
        <div className='carousel-container'>
            <Carousel 
            showThumbs={false}
            infiniteLoop={true}
            interval={2000}
            autoPlay={true}
            stopOnHover={true}
            useKeyboardArrows={true}
            width='80%'>
                { adBanners!== undefined && Object.entries(adBanners).length > 0 && adBanners.map((item, index) => {
                    return (
                    <div key={index}>
                        <img src={`${baseURL()}files/system?file=${item.attrs.image}`} />
                        {/* <p className="legend">{item.name}</p> */}
                    </div>
                    )
                }) }
            </Carousel>
        </div>
    )
}

export default AdBanners