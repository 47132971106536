import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../../Shared/Components/Header';
import Menu from '../../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../../Shared/Utils/Common';
import api from '../../Shared/Utils/api';
import { DataTable } from '../../Shared/Components';
import { confirmAlert } from 'react-confirm-alert';

function List (props) {
  const user = getUser();
  const history = useHistory();
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [notesList, setNotesList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  
  useEffect(() => {

    if(!isApiCompleted)
    {
      setIsLoading(true)
      api.get('user/v1/notes').then(response => {
        setIsApiCompleted(true)
        setIsLoading(false)
        setNotesList(response.data)
      }).catch(error => {
        setIsLoading(false)
        addToast(error, { appearance: 'error', autoDismiss: true })
      })
    }
})

const columns = React.useMemo(
  () => [      
    {
      Header: 'Lecture',
      accessor: 'title',
    },
    {
      Header: 'Content',
      accessor: row => `${row.content}`,
    },
    {
      Header: 'Action',
      accessor: 'actions',                 
      buttons_data: [{
        url: '/notes-edit',
        action: 'edit',
        icon: 'fa-edit',
        parameters: {}
      },{
        url: '',
        action: 'delete',
        text: 'Delete',
        icon: 'fa-trash',
        parameters: {}
      }]        
    },
  ],
  []
)

const handleDeleteButton = (menudata) => {      
  
  confirmAlert({
    title: 'Confirm to delete',
    message: 'Are you sure want to delete',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          api.delete(`user/v1/notes/${menudata.id}`).then(function (response) {
            addToast('Notes removed successfully', { appearance: 'success', autoDismiss: true });
            const newlist = notesList.filter((ll) => { return ll.id !== menudata.id});
            setNotesList(newlist)
          }).catch(error => {
            addToast(error, { appearance: 'error', autoDismiss: true });
          });
        }
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });
}

    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">My Home</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header p-2">
                  Notes
              </div>
              <div className="card-body">
                { notesList && notesList.length > 0 &&
                  <DataTable
                    data={notesList}
                    columns={columns}
                    search={true}
                    isLoading={isLoading}
                    columnFilter={false}
                    rowCountIncrease={true}
                    actionButton={false}
                    bottomPagination={true}
                    onEditButtonClick={handleDeleteButton}
                    rowSelect={false}
                  />
                }
                { notesList && notesList.length === 0 &&
                <p className='text-center'>No records found</p>
                }
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </div>)
}

export default List