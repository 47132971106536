export const getStoredAuthToken = () => localStorage.getItem('authToken');
export const storeAuthToken = token => localStorage.setItem('authToken', token);
export const removeStoredAuthToken = () => localStorage.removeItem('authToken');

export const getStoredRefAuthToken = () => localStorage.getItem('authRefToken');
export const storeAuthRefToken = token => localStorage.setItem('authRefToken', token);
export const removeStoredAuthRefToken = () => localStorage.removeItem('authRefToken');

export const storeUserId = userid => localStorage.setItem('userid', userid)
export const getStoredUserId = () => localStorage.getItem('userid');
export const removeStoredUserId = () => localStorage.removeItem('userid');

export const storeAuthUser = user => localStorage.setItem('authUser', user)
export const getAuthUser = () => localStorage.getItem('authUser');
export const removeAuthUser = () => localStorage.removeItem('authUser');

export const storeUserDetails = useInfo => localStorage.setItem('loggedInUserDetails', useInfo)
export const getUserDetails = () => localStorage.getItem('loggedInUserDetails');
export const removeUserDetails = () => localStorage.removeItem('loggedInUserDetails');