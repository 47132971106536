import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding: 200px 0;
  text-align: center;
  position: fixed;
  top: 0; left: 0; z-index: 9999;
  width: 100vw; height: 100vh;
  background: rgb(78 78 78 / 26%);
  transition: opacity 0.2s;
`;
