import React, { useState,useEffect } from 'react';
import Axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import Footer from '../Shared/Components/Footer';
import api from '../Shared/Utils/api';
import { appURL } from '../Shared/Utils/Common';

const PaymentSuccess = () => {
    const [txnid, setTxnId] = useState('');
    const [amount, setAmount] = useState('');
    const [status, setStatus] = useState('');
    const [orderDetail, setOrderDetail] = useState('');
    const [isApiCompleted, setIsApiCompleted] = useState(false);
    const [isResponseApiCompleted, setIsResponseApiCompleted] = useState(false);
    const { addToast } = useToasts();
    localStorage.removeItem('cartId');
    localStorage.removeItem('checkOutResponse');

    useEffect(() => {
      if(!isApiCompleted && txnid !=='' && status!=='' && orderDetail!=='')
      {
        api.post('user/cart/v1/order',{'order_detail': orderDetail,
        'order_id': txnid,
        'order_status': status}).then(response => {
          setIsApiCompleted(true)
        }).catch(error => {
          addToast(error.message, { appearance: 'error', autoDismiss: true })
        })
      }
    });

    useEffect(() => {
        Axios.get(`${appURL()}/getResponse`).then(res=>{
          setTxnId(res.data.txnid);
          setAmount(res.data.amount);
          setStatus(res.data.status);
          setOrderDetail(res.data.orderDetail)
        });
    }, [txnid, amount, status, orderDetail]);
  
    return( <>
      <Header/>
      <Menu/>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Payment Status</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className='row'>
              <div className="col-md-12">
                <div className="card card-success">
                  <div className="card-header">
                    <h3 className="card-title">Payment success</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className='col-sm-2 col-form-label'>TxnId</label>
                        <div className='col-sm-10'>
                        {txnid}
                        </div>
                    </div>
                    <div className="form-group row">
                      <label className='col-sm-2 col-form-label'>Amount</label>
                      <div className='col-sm-10'>
                        {amount}
                        </div>
                    </div>
                    <div className="form-group row">
                      <label className='col-sm-2 col-form-label'>Status</label>
                      <div className='col-sm-10'>
                        {status}
                        </div>
                    </div>
                    <div className="form-group row">
                      <label className='col-sm-2 col-form-label'>Description</label>
                      <div className='col-sm-10'>
                        {orderDetail.error_Message}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
      </>
    );
  }
  
export default PaymentSuccess;