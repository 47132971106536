import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../state";
import { useToasts } from 'react-toast-notifications';
import { removeUserSession, getToken, getRefreshToken } from '../Utils/Common';
import api from '../Utils/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Header = forwardRef((props, ref) => {

    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false)
    const [isCartApiCompleted, setIsCartApiCompleted] = useState(false)
    const [cart, setCart] = useState({});
    const cartItems = useGlobalState('subjects')[0]
    let history = useHistory();
    const handleLogout = () => {

        confirmAlert({
            title: 'Confirm to logout',
            message: 'Are you sure want to logout',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    removeUserSession(); 
                    history.push('/');                    
                }
            },
            {
                label: 'No',
                onClick: () => {}
            }
            ]
        });        
    }
        
    useEffect(() => {
        if(!isCartApiCompleted)
        {
            setIsLoading(true)
            api.get('user/cart/v1/open').then(response => {
                setIsCartApiCompleted(true)
                setCart(response.data)
                props.handleCartItems(response.data)
            }).catch(error => {
                // addToast(error, { appearance: 'error', autoDismiss: true })
            })
        }
    })

    useImperativeHandle(ref, () => ({
        refreshCart: refreshCart        
    }))

    const refreshCart = () => {
        setIsCartApiCompleted(false)
    }
    

    let sumTotal = 0
    if(cart.items &&  cart.items.length >0 )
    {
        sumTotal = cart.items.reduce(function (sum, current) {
            return sum + current.cost;
        }, 0);
    }

    const removeItemFromCart = (itemId) => {
        
        // props.handlePageLoader(true)
        // api.delete(`user/cart/v1/item/${itemId}`).then(response => {
        //     addToast('Selected subject removed successfully.', { appearance: 'success', autoDismiss: true});
        //     setIsCartApiCompleted(false)
        //     props.handlePageLoader(false)
        // }).catch(error => {
        //     props.handlePageLoader(false)
        
        //     addToast(error, { appearance: 'error', autoDismiss: true })
        // })

        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure want to delete',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    props.handlePageLoader(true)
                    api.delete(`user/cart/v1/item/${itemId}`).then(response => {
                        addToast('Selected subject removed successfully.', { appearance: 'success', autoDismiss: true});
                        setIsCartApiCompleted(false)
                        props.handlePageLoader(false)
                    }).catch(error => {
                        props.handlePageLoader(false)
                    })
                    }
                },
                {
                label: 'No',
                onClick: () => {}
                }
            ]
        });
    }

    const handleCheckOut = () => {
          
        props.handlePageLoader(true)
        api.post(`user/cart/v1/checkout`,{"amount":sumTotal,"cart_id":cart.cart_id}).then(response => {
            props.handlePageLoader(false)
            localStorage.setItem('cartId', cart.cart_id);
            localStorage.setItem('checkOutResponse', response.data);
            props.handleCartCheckout()
            addToast('Payment link sent', { appearance: 'success', autoDismiss: true});
            history.push('payment-confirmation')
          }).catch(error => {
            props.handlePageLoader(false)
            addToast(error, { appearance: 'error', autoDismiss: true })
          })
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Navbar Search */}
                {/* <li className="nav-item">
                <a className="nav-link" data-widget="navbar-search" href="#" role="button">
                    <i className="fas fa-search" />
                </a>
                <div className="navbar-search-block">
                    <form className="form-inline">
                    <div className="input-group input-group-sm">
                        <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                            <i className="fas fa-search" />
                        </button>
                        <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                            <i className="fas fa-times" />
                        </button>
                        </div>
                    </div>
                    </form>
                </div>
                </li> */}
                {/* Messages Dropdown Menu */}
                {/* <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                    <i className="far fa-comments" />
                    <span className="badge badge-danger navbar-badge">3</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" className="dropdown-item">
                    <div className="media">
                        <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                        <div className="media-body">
                        <h3 className="dropdown-item-title">
                            Brad Diesel
                            <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                        </h3>
                        <p className="text-sm">Call me whenever you can...</p>
                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                        </div>
                    </div>
             
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
    
                    <div className="media">
                        <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                        <div className="media-body">
                        <h3 className="dropdown-item-title">
                            John Pierce
                            <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
                        </h3>
                        <p className="text-sm">I got your message bro</p>
                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                        </div>
                    </div>
  
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">

                    <div className="media">
                        <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                        <div className="media-body">
                        <h3 className="dropdown-item-title">
                            Nora Silvester
                            <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
                        </h3>
                        <p className="text-sm">The subject goes here</p>
                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                        </div>
                    </div>
    
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                </div>
                </li> */}
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#" title="Cart">
                        <i className="fas fa-shopping-cart" />
                        <span className="badge badge-warning navbar-badge">{cart.items && cart.items.length}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">Cart items</span>
                        <div className="dropdown-divider" />
                        {cart.items && cart.items.length > 0 && cart.items.map((items, index) => {
                            return(
                            <div key={index} className="dropdown-item text-overflow">
                                <small>{items.title}</small><br/>
                                <small className="cart-product-summary">{items.summary}</small><br/>
                                <small className="cart-qty">Qty: {items.quantity}</small>
                                <small className="cart-product-price">₹{items.cost}</small>
                                <span onClick={() => removeItemFromCart(items.id)} className="float-right text-muted text-sm cursor-pointer">
                                    <i className="fas fa-trash danger trash-icon" />
                                </span>
                            </div>
                            )
                        })
                            
                        }
                        {!cart.items && 
                            <p style={{'padding':'20px','textAlign':'center'}}>Cart empty</p>
                        }

                        <div className="dropdown-divider" />
                            <div className="row" style={{'padding': '10px'}}>
                                <div className="col">Total : ₹{sumTotal}</div>
                                <div className="col">
                                { sumTotal > 0 &&
                                    <button onClick={()=>handleCheckOut()} type="button" className="btn btn-block btn-primary btn-sm brand-secondary-btn">Proceed to pay</button>
                                }
                                </div>
                            </div>
                        </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" onClick={() => { return history.push('/wallet'); }} role="button" title="Wallet">
                        <i className="fas fa-wallet" />
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button" title="Fullscreen">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
                <li>
                    <a className="nav-link" onClick={handleLogout} data-widget="control-sidebar" data-slide="true" href="#" role="button" title="Logout"><i className="fas fa-sign-out-alt" /></a>
                </li>
            </ul>
            </nav>
        </>
    )
    
})

export default Header;