import {axios} from 'axios';
import { storeAuthRefToken, storeAuthToken } from './authToken';

export const baseURL = () => {
  // return 'https://staging.jjedutech.in:8443/api/';
  return 'https://jjedutech.in:8443/api/';
}

export const appURL = () => {
  // return 'https://web.jjedutech.in:8643';
  // return 'http://webapp.local';
  return 'http://localhost:3000';
}

export const calcHash = (hashStr) => {
  // let cryp = crypto.createHash('sha512');
  // cryp.update(hashStr);
  // let hash = cryp.digest('hex');
  // return hash;
}

export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

export const getRefreshToken = () => {
  return sessionStorage.getItem('refresh_token') || null;
}

export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}

export const setUserSession = (token, refresh_token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('refresh_token', refresh_token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

export const generateNewToken = () => { 

  const token = getToken();
  const refreshToken = getRefreshToken();

  axios({
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    url: `${baseURL()}oauth2/token/refresh-token?refresh_token_id=${refreshToken}&token_id=${token}`,
    data: {}
  }).then(function (response) {
     sessionStorage.setItem('token', response.data.token);
     sessionStorage.setItem('refresh_token', response.data.refresh_token);
     storeAuthToken(response.data.token)
     storeAuthRefToken(response.data.refresh_token)
  }).catch(error => {
    console.log(error.config)
    console.log(error.message)
  });

}

export const loadVideo = file => new Promise((resolve, reject) => {
  try {
      let video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
          resolve(this)
      }

      video.onerror = function () {
          reject("Invalid video. Please select a video file.")
      }

      video.src = window.URL.createObjectURL(file)
  } catch (e) {
      reject(e)
  }
})

export const secondsToHourseMinutesSecond = (duration) => {
  const sec = parseInt(duration, 10);
  let hours   = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - (hours * 3600)) / 60);
  let seconds = sec - (hours * 3600) - (minutes * 60);
  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds
}

export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const groupArrayOfObjects = (list, key) => {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const getDiscountPrice = (price, discount, type) => {
  
  var mrp = Number(price);
  var dis = Number(discount);
  if(type==='round'){
    var totalValue = Math.round(mrp - ((mrp * dis)/100))
  }
  if(type==='ceil'){
    var totalValue = Math.ceil(mrp - ((mrp * dis)/100))
  }
  if(type==='floor'){
    var totalValue = Math.floor(mrp - ((mrp * dis)/100))
  }  
  
  return totalValue
}