import styled from 'styled-components';

export const ActionButton = styled.button`
  padding: 6px 10px !important;
  height: auto;
  border-radius: 7px;
  border: 1px solid #d5d5d5;
  &.btn-md {
    padding: 12px 18px !important;
  }
  &.btn-sm {
    padding: 10px 15px !important;
  }
  .mt-13{margin-top:13px} 
  &.table-action-dropdown-btn {
    padding: calc(.65rem + 1px) calc(1.25rem + 1px) !important;
    font-size: .925rem !important;
    border-radius: 0.475rem !important;
    .btn .svg-icon {
      flex-shrink: 0;
      line-height: 0;
      margin-right: 0.5rem;
      svg {
        height: 1.15rem!important;
        width: 1.15rem!important;
      }
    }
    
  }
  .date {
    font-family: 'PoppinsBold';
  }
`;

export const Img = styled.img`
  width: ${props => props.width ? props.width : "250px"};
`;

export const ImgBox = styled.div`
`;

export const ActionLinks = styled.div`

ul{
    display: flex;
    margin:0;
    padding:0;
    li {
        display:flex;
        margin-right: 10px;
        cursor:pointer;
        :hover{
            color:red;
        }
    }
}
`

export const FormElement = styled.div`
  padding: ${props => props.padding ? props.padding : "25px 40px 35px"};
`;


export const FieldsInline = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px!important;
  margin-right: -4px!important;
  margin-top: 15px;
`;

export const Col = styled.div`
  width: ${props => props.width ? props.width : "50%"};
  ${props => props.maxWidth ? "max-width:" + props.maxWidth : ""};
  ${props => props.display ? "display:" + props.display : ""};
  @media (max-width: 768px) {
    width: ${props => props.mobwidth ? props.mobwidth : "100%"};
  }
  .form-filed-inline {
    margin-left: 4px!important;
    margin-right: 4px!important;
  }
  .enq-form-field{
    label {
      font-family: 'PoppinsRegular';
      font-size: 16px;
      line-height: 20px;
    }
    
    input, select, textarea {
      padding: 0.7rem 0.75rem;
      background: #FFFFFF;
      border: 1px solid #8b9097;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  
  &.pr-2 {
    padding-right: 15px;
  }
  &.image-placeholder {
    img {
      width: 100%;
      border: 1px solid #ccc;
      padding: 10px;
      margin: 10px 0;
      border-radius: 10px;
    }
  }
  .mt-10{
    margin-top: 9px;
    margin-left: 10px;
  }
  .mt-30{
    margin-top: 33px;
  }
  .mt-45{
    margin-top: 46px;
  }
  .mt-25{
    margin-top: 25px;
  }
`
export const Row = styled.div`
  display: ${props => props.display ? props.display : 'block'};
  margin: 10px 0;
  &.align-center {
    text-align: center;
  }
  &.small-text {    
    line-height: 18px;
  }
`
export const ConfirmModalPanel = styled.div`
    padding: 20px;
    .header {
        font-size: 55px;
        font-family: 'PoppinsBold';
        padding: 10px;
        margin-bottom: 20px;
        text-align: center;
        color: #f80;
    }
    .body {
        text-align: center;
        padding: 20px;
        margin-bottom: 50px;
        font-family: 'PoppinsMedium';
        line-height: 1.5;
    }

    .confirmBtns {
        text-align: center;
    }
`