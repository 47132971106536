import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useHistory } from "react-router-dom";
import { baseURL, getToken, setUserSession } from '../Shared/Utils/Common';
import {useToasts} from 'react-toast-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { storeAuthRefToken, storeAuthToken } from '../Shared/Utils/authToken';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import OtpInput from 'react-otp-input';

function Registration(props) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isApiCompleted, setIsApiCompleted] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [serverStatus, setServerStatus] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(location.state ? location.state : '')
  const [isValidPhone, setIsValidPhone] = useState(location.sessionId ? true : false)
  const [isVerifiedOtp, setIsVerifiedOtp] = useState(false)
  const [otp, setOtp] = useState('')
  const [isOtpSent, setIsOtpSent] = useState(location.sessionId ? true : false)
  const [otpSessionId, setOtpSessionId] = useState(location.sessionId ? location.sessionId : '')
  const [isOTPVerifying, setIsOTPVerifying] = useState(false)
  const [showGenerateOtp, setShowGenerateOtp] = useState(false)
  const [otpSentCount, setotpSentCount] = useState(location.sessionId ? 1 : 0)
  const [counter, setCounter] = useState(59);
  const maxLimit = 3
  const token = getToken()

  const sendOTP = async(phone) => {
      await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/json',
          'Authorization':  token ? `Bearer ${token}` : ''
        },
        url: `${baseURL()}user/v1/otp/generate`,
        data: {'mobile':phone,'otp-type':'Registration'}
      }).then(function (response) {
        if(response['data']['session-id'])
        {
          setOtpSessionId(response['data']['session-id'])
          addToast('OTP sent to your phone number. Please check and verify it.', { appearance: 'success', autoDismiss: true});
          setIsOtpSent(true)
          setShowGenerateOtp(false)
          setIsOtpSent(true)
          setIsValidPhone(true)
          let tempcnt = otpSentCount
          tempcnt = tempcnt +1
          setotpSentCount(tempcnt)
          if(tempcnt < maxLimit)
          {
            setCounter(59)
          }          
        }
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        addToast(error.message, { appearance: 'error',autoDismiss: true });
      });
      
  }
  
  const validateOTP = async() => {
    await axios({
      method: 'post',
      headers: { 'Content-Type': 'application/json',
        'Authorization':  token ? `Bearer ${token}` : ''
      },
      url: `${baseURL()}user/v1/otp/validate`,
      data: {'mobile':phoneNumber,'session-id':otpSessionId,'otp':otp}
    }).then(function (response) {
      if(response['data']['status-code']===200)
      {
        addToast(response['data']['message'], { appearance: 'success', autoDismiss: true});
        setIsVerifiedOtp(true)
      }else{
        addToast(response['data']['message'], { appearance: 'error', autoDismiss: true});
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      addToast(error.response.data.message, { appearance: 'error',autoDismiss: true });
      setOtp('')
    });
  }

  useEffect(() => {
    const timer =
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if(phoneNumber && phoneNumber.length >= 13)
    {
      setShowGenerateOtp(true)
    }else{
      setShowGenerateOtp(false)
    }
  })

  useEffect(() => {
    if (otp.length === 6) {
       validateOTP()
    }
  },[otp])


  const RegistrationSchema = Yup.object().shape({
    display_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    password: Yup.string()
      .min(2, 'Should be more than 6 character')
      .required('Required'),
      confirm_password: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });
  
  const handleOTPChange = (otp) => {
    setOtp(otp)
  }

  const AutoLogin = (phoneNumber, password) => {
    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      url: `${baseURL()}oauth2/token`,
      data: { username: phoneNumber, password: password }
    }).then(function (response) {
      setLoading(false);
      setUserSession(response.data.token, response.data.refresh_token, response.data.user)
      storeAuthToken(response.data.token)
      storeAuthRefToken(response.data.refresh_token)
      props.history.push('/dashboard')
    }).catch(error => {
      setLoading(false);
      addToast('Invalid credentials', { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <div>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>JJ EDU: Learn Apptastically | Student Registration</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback" />
    <link rel="stylesheet" href="../../../plugins/fontawesome-free/css/all.min.css" />
    <link rel="stylesheet" href="../../../plugins/icheck-bootstrap/icheck-bootstrap.min.css" />
    <link rel="stylesheet" href="../../../dist/css/adminlte.min.css" />
    <div className={`login-box`}>
        <Fragment>
          <div className="login-logo">
            <a href="/">
              <img src="/dist/img/logo.svg" alt="JJ Edu Logo" className="brand-image"/></a>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
            
              <p className="login-box-msg">Register with us</p>
                <Formik
                  initialValues={
                    { 
                      display_name: '', 
                      email: '',
                      password: '',
                      phone: '',
                      referral_code: '',
                      city: ''
                    }
                  }
                  validationSchema={RegistrationSchema}

                  onSubmit={ async (values) => {
                    
                    if(phoneNumber && phoneNumber!='')
                    {
                    setLoading(true);
                    axios({
                      method: 'post',
                      headers: { 'Content-Type': 'application/json' },
                      url: `${baseURL()}user/v1/signup`,
                      data: { 
                        display_name: values.display_name, 
                        email: values.email,
                        password: values.password,
                        phone: phoneNumber.toString(),
                        referral_code: values.referral_code,
                        city: values.city
                       }
                    }).then(function (response) {
                      setLoading(false);
                      AutoLogin(phoneNumber, values.password)
                        // setUserSession(response.data.token, response.data.refresh_token, response.data)
                        // setUserSession(response.data.token, response.data.refresh_token, response.data.user)
                        // storeAuthToken(response.data.token)
                        // storeAuthRefToken(response.data.refresh_token)
                        // props.history.push('/dashboard')
                    }).catch(error => {
                      setLoading(false);
                      addToast(error.response.data.message, { appearance: 'error', autoDismiss: true });
                    });
                  }else{
                    addToast('Enter valid phone number', { appearance: 'error', autoDismiss: true });
                  }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className="input-group mb-3">
                        <Field type="text"
                          name='display_name'
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.display_name}
                          placeholder="Display name" />
                          <ErrorMessage name="display_name" component="div" className='mandatory_error' />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="fas fa-user" />
                            </div>
                          </div>
                      </div>

                      <div className="input-group mb-3">
                        <Field type="email"
                          name='email'
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Email" />
                          <ErrorMessage name="email" component="div" className='mandatory_error' />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="fas fa-envelope" />
                            </div>
                          </div>
                      </div>

                      <div className="input-group mb-3">
                      <PhoneInput
                          placeholder="Phone number"
                          defaultCountry="IN"
                          value={phoneNumber}
                          disabled={isVerifiedOtp}
                          onChange={setPhoneNumber}/>
                          { showGenerateOtp && !isOtpSent && <div className='generate-otp'>
                              <Link 
                              to='#'
                              onClick={() => {sendOTP(phoneNumber)}}
                              className="text-center send-otp">
                                Send OTP
                            </Link>
                            </div>
                          }
                          <ErrorMessage name="phone" component="div" className='mandatory_error' />
                      </div>
                      {isValidPhone && isOtpSent && !isVerifiedOtp &&
                        <div className="input-group mb-3">
                          <OtpInput
                            value={otp}
                            onChange={handleOTPChange}
                            numInputs={6}
                            separator={<span>-</span>}
                            inputStyle='form-control'
                            containerStyle='otp-boxes'
                            isInputNum={true}
                          />
                          { counter > 0 && otpSentCount < maxLimit &&
                            <p style={{'paddingTop':'20px'}}>Resend OTP in 00 : {counter}</p>
                          }

                          { counter == 0 && otpSentCount < maxLimit && <>
                          <br />
                          <Link 
                          style={{'paddingTop':'15px'}}
                          to='#'
                          onClick={() => {sendOTP(phoneNumber)}}
                          className="text-center">
                            Resend OTP
                          </Link>
                          </>
                          }

                          { otpSentCount == maxLimit && <>
                          <br /><p style={{'marginTop':'20px','color':'red'}}>Reached maximum attempt to send OTP</p>
                          </>
                          }
                        </div>
                      }
                      <div className="input-group mb-3">
                      <Field type="password"
                          name='password'
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Password" />
                          <ErrorMessage name="password" component="div" className='mandatory_error' />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-lock" />
                          </div>
                        </div>
                      </div>

                      <div className="input-group mb-3">
                      <Field type="password"
                          name='confirm_password'
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirm_password}
                          placeholder="Retype Password" />
                          <ErrorMessage name="confirm_password" component="div" className='mandatory_error' />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-lock" />
                          </div>
                        </div>
                      </div>

                        
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="icheck-primary">
                          <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" />
                            <label htmlFor="agreeTerms">
                            I agree to the <Link target="_blank"
                              to={{pathname:'https://jjtax.in/compliance/jjedu/terms-and-conditions.html'}}
                              className="text-center"> terms and conditions</Link>
                            
                            <a href="#"></a>
                          </label>
                        </div>
                      </div>
                      </div>
                    <div className='row text-center'>
                      <div className="col-12">
                        <br/>
                        <button type="button" value={loading ? 'Loading...' : 'Registration'} onClick={handleSubmit} disabled={isSubmitting || !isVerifiedOtp} className="btn btn-primary btn-block brand-secondary-btn">Register</button>
                      </div>
                    </div>
                </Form>
                )}
              </Formik>
                  <div className="social-auth-links text-center">
              </div>
              <Link 
                to='/login'
                className="text-center">
                  I already have a membership
                </Link>
            </div>
          </div>
        </Fragment>
      {/* } */}
    </div>
  </div>
  );
}

export default Registration;