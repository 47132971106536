import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../../Shared/Components/Header';
import Menu from '../../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../../Shared/Utils/Common';
import api from '../../Shared/Utils/api';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function Edit (props) {
  const user = getUser();
  const history = useHistory();
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [notesData, setNotesData] = useState(props.history.location.state && props.history.location.state.data)
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {

    if(!isApiCompleted)
    {
      setIsLoading(true)
      
    }
})

const NotesUploadSchema = Yup.object().shape({
  content: Yup.string()
    .min(2, 'Too Short!')
    .required('Required')
});

    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">My Home</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header p-2">
                  Notes Edit
              </div>
              <div className="card-body">
              <Formik
                      initialValues={
                        { 
                          content: notesData.content ?  notesData.content : ''
                        }
                      }
                      validationSchema={NotesUploadSchema}

                      onSubmit={ async (values) => {
                        setLoading(true);
                        api.put(`user/v1/notes/${notesData.id}`,{content: values.content}).then(function (response) {
                          addToast('Notes details updated successfully.', { appearance: 'success', autoDismiss: true});
                          setLoading(false);
                          history.push('/my-notes');
                        }).catch(error => {
                          setLoading(false);
                          addToast(error.message, { appearance: 'error',autoDismiss: true });
                        });
                      }}
                      >
                  {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-6'>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Title</label>
                                    { notesData && notesData.title && 
                                      <input type="text" className="form-control" value={notesData.title ? notesData.title : ''} disabled />
                                    }
                                  </div>
                                </div>
                                
                                <div className='col-md-12'>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Content</label>
                                    <textarea className="form-control"
                                    name='content'
                                    id='content'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={values.content}
                                    rows="3" placeholder="Enter ..."></textarea>
                                    <ErrorMessage name="content" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <div className='row'>
                              <div className='offset-md-3 col-md-1'>
                                  <button type="button" onClick={() => history.push('/my-notes')} className="btn brand-primary-btn ">Cancel</button>
                              </div>
                              <div className='col-md-2'>
                                  <button type="submit" disabled={isSubmitting} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Update'}</button>
                              </div>
                            </div>
                          </div>
                        </Form>
                        )}
                  </Formik>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </div>)
}

export default Edit