import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { baseURL, getUser } from '../Utils/Common';

function Menu () {
  const user = getUser();
  const location = useLocation()
  const [clickedMenu, setClickedMenu] = useState('')

  const  links = ([
      {
        id: 1,
        name: "Dashboard",
        to: "/dashboard",
        className: "nav-item",
        parent: false,
        icon: "fa-tachometer-alt"
      },
      {
        id: 2,
        name: "My Home",
        to: "#",
        className: "nav-item",
        parent: true,
        icon: "fa-th",
        subMenus: [
          {
            id: 10,
            name: "Courses",
            to: "/my-courses",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },
          {
            id: 11,
            name: "Bookmarks",
            to: "/my-bookmarks",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },
          {
            id: 12,
            name: "Notes",
            to: "/my-notes",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },
        ]
      },
      {
        id: 3,
        name: "Quiz",
        to: "/quiz",
        className: "nav-item",
        icon: "fa-trophy",
        parent: false,
      },
      {
        id: 4,
        name: "Settings",
        to: "#",
        className: "nav-item",
        parent: true,
        icon: "fa-cog",
        subMenus: [
          {
            id: 5,
            name: "Profile",
            to: "/my-profile",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },
          // {
          //   id: 6,
          //   name: "Notifications",
          //   to: "#",
          //   className: "nav-item",
          //   icon: "fa-circle nav-icon",
          // },
          {
            id: 7,
            name: "Wallet",
            to: "/wallet",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },{
            id: 8,
            name: "Terms & Conditions",
            to: "/terms-and-conditions",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },{
            id: 9,
            name: "Privacy Policy",
            to: "/privacy-policy",
            className: "nav-item",
            icon: "fa-circle nav-icon",
          },
          // {
          //   id: 10,
          //   name: "Logout",
          //   to: "#",
          //   className: "nav-item",
          //   icon: "fa-circle nav-icon",
          // }
        ]
      }
    ])
    
    const handleMenuClick = (lP) => {
      setClickedMenu(lP)
    }

    return (
    <>
        <aside className="main-sidebar sidebar-dark-primary">
        <a href="/" className="brand-link text-center">
          <img src="/dist/img/logo-head-alt.png" alt="JJ Edu Logo" className="brand-image" />
          <p>Learn Appstatically</p>
        </a>
        
        <div className="sidebar">
          <div className="user-panel d-flex">
            <div className="image">
                { user.avatar && user.avatar!=='' &&
                  <img src={`${baseURL()}files/user/${user.user_id}?file=${user.avatar}`} className="img-circle elevation-2" alt="User Image" />
                }
                { !user.avatar &&
                  <img src="/dist/img/avatar-5.png" className="img-circle elevation-2" alt="User Image" />
                }              
            </div>
            <div className="info">
              <span className='d-block brand-font-color'>{user.displayname}</span>
              {/* <Link to='/my-profile'
                  className='d-block brand-font-color'>
                    {user.displayname}
                </Link> */}
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                 {links.map(link => {
                   let activeMenu = false
                   let activeSubMenu = false
                   
                     if(clickedMenu)
                     {
                        if(clickedMenu.id === link.id)
                        {
                          activeMenu = true
                          activeSubMenu = true
                        }
                     }else{
                      if(location.pathname && location.pathname === link.to)
                      {
                         activeMenu = true
                      }
 
                      if(link.subMenus && link.subMenus.length > 0)
                      {
                         let checkSubMenu = link.subMenus.filter(sub => sub.to === location.pathname)
                         if(checkSubMenu.length > 0)
                         {
                           activeMenu = true
                           activeSubMenu = true
                         }
                      }
                     }
                  
                  return (
                      <li
                        key={link.id} 
                        className={`${link.className} ${activeSubMenu ? 'menu-is-opening menu-open' : ''}`} >
                        <Link to={link.to} 
                          className={`nav-link ${ activeMenu ? 'active':''}`}
                          onClick={()=>handleMenuClick(link)}>
                            <i className={`nav-icon fas ${link.icon}`} />
                            <p>{link.name}</p>
                            {link.parent===true && <i className="right fas fa-angle-left" /> }
                        </Link>
                        {link.parent===true && 
                         <ul data-widget="treeview" className="nav nav-treeview">
                           {link.subMenus.map((sub) => {
                             return(
                              <li key={sub.id} 
                              className="nav-item">
                                <Link to={sub.to} 
                                  className={`nav-link ${ location.pathname && location.pathname === sub.to ? 'active':''}`}>
                                    <i className={`nav-icon ${ location.pathname && location.pathname === sub.to ? 'fas':'far'} ${sub.icon}`} />
                                    <p>{sub.name}</p>
                                </Link>
                            </li>
                             )
                           })}
                         </ul>
                        }
                      </li>
                    );
                  })}
                {/* <li className='nav-item'>
                  <Link to='#'
                    className='nav-link'>
                      <i className='nav-icon fas fa-sign-out-alt' />
                      <p>Settings</p>
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </aside>
      </>)
}

export default Menu
