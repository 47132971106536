import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { baseURL, getToken, getUser, removeUserSession } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import { PageLoader } from '../Shared/Components';
import Timer from '../Shared/Components/Common/Timer';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AdBanners from '../Shared/Components/Common/AdBanners';

function QuizExam (props) {
  const user = getUser()
  const token = getToken()
  const history = useHistory()
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [topicList, setTopicList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [selectedTopic, setSelectedTopic] = useState('')
  const [topicQuestions, setTopicQuestions] = useState('')
  const [isStartExam, setIsStartExam] = useState(false)
  const [levelOfQuiz, setLevelOfQuiz] = useState(0)
  const [currentQuestionNo, setCurrentQuestionNo] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [clickedAns, setClickedAns] = useState('')
  const [isShowAnswer, setIsShowAnswer] = useState(false)
  const [isDisableAnsOpt, setIsDisableAnsOpt] = useState(false)
  const [seconds, setSeconds] = useState(180)
  const [score, setScore] = useState(0)
  const [isExamSubmitted, setIsExamSubmitted] = useState(false)
  const [isShowScoreBoard, setIsShowScoreBoard] = useState(false)
  const [finalScore, setFinalScore] = useState('')
  const [quizDuration, setQuizDuration] = useState(0)
  const [isLevelCompleted, setIsLevelCompleted] = useState(false)
  const [instructions, setInstructions] = useState()
  const [adBanners, setAdBanners] = useState()

  useEffect(() => {
    if(topicQuestions!=='')
    {
    handleStartExam()
    }
  },[topicQuestions])

  useEffect(() => {
    if(!isApiCompleted)
    {
      setIsLoading(true)
      api.get('quiz/config/v1/topics?type=0').then(response => {
        setIsApiCompleted(true)
        setIsLoading(false)
        setTopicList(response.data)
      }).catch(error => {
        setIsLoading(false)
        addToast(error, { appearance: 'error', autoDismiss: true })
      })

      api.get('banner/advt/v1').then(response => {
        setAdBanners(response.data)
      })

    }
  })

  const handleTopicClick = (val) => {
    setSelectedTopic(val)
    // var iframe = document.getElementById("myIframe");
    // iframe.onload = function(){
      // iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    // }
  }

  const getQuestions = () => {
    setIsLoading(true)
    api.post('quiz/v2',{'topic': selectedTopic.name,'type':'0','user_id': user.user_id}).then(res =>{
      if(res.status===410)
      {
        setSelectedTopic('')
        handleNoNewQuestions(res.data.message)
      }
      if(res.status===200)
      {
        setTopicQuestions(res.data)
      }
      setIsLoading(false)
    }).catch(error => {
      if(error && error.message)
      {
        handleNoNewQuestions(error.message)
      }
      setIsLoading(false)
    })
  }

  const handleBackClick = () => {

    if(selectedTopic!=='')
    {
      setSelectedTopic('')
      setIsStartExam(false)
      setIsLoading(false)
    }
  }


  const handleStartExam = () => {
    console.log(topicQuestions)
    setIsLevelCompleted(false)
    setFinalScore('')
    setIsExamSubmitted(false)
    setIsShowScoreBoard(false)
    setCurrentQuestionNo(0)
    setIsApiCompleted(false)
    setTotalQuestion(topicQuestions.levels[levelOfQuiz].questions.length)
    let tempTime = new Date()
    // tempTime.setSeconds(tempTime.getSeconds() + topicQuestions.levels[levelOfQuiz].settings.duration)
    let getSeconds = topicQuestions.levels[levelOfQuiz].settings.duration * 60;
    tempTime.setSeconds(tempTime.getSeconds() + getSeconds)
    setQuizDuration(tempTime)
    setIsStartExam(true)
  }

  const loadQuestion = async (qid,option) => {
    
    await api.post(`quiz/v1/${topicQuestions.quiz_id}/answer`,
    {
      "duration":'16',
      "given":option,
      "question":qid
    }).then(res =>{
      setScore(score + res.data.score)
      
      if(totalQuestion === currentQuestionNo + 1)
      { 
        if(res.data.level_completed !== null )
        {
          if(res.data.level_completed)
          {
            setIsLevelCompleted(true)
            setIsStartExam(false)
          }else{
            submitQuiz(3)
          }
        }        
      }else{
        /* Load next question */ 
        let tempCnt = currentQuestionNo
        setCurrentQuestionNo(tempCnt+1)
        setIsShowAnswer(false)
        setIsDisableAnsOpt(false)
      }      
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
    })
  }

  const submitQuiz = (state) => {
    api.post(`quiz/v1/${topicQuestions.quiz_id}/finish`,
    {
      "state":state,
      "user_id": user.user_id
    }).then(res =>{
        console.log(res)
      if(res.status===200)
      {
        setIsExamSubmitted(true)
        setIsShowScoreBoard(true)
        setFinalScore(res.data)
        setClickedAns('')
        setIsShowAnswer(false)
      }
      setIsLoading(false)
    }).catch(error => {
      if(error.message)
      {
        addToast(error.message, { appearance: 'error', autoDismiss: true })
      }
      setIsLoading(false)
    })
  }

  const handleAnswerClick = (qid,option) => {
    setIsLoading(true)
    setIsDisableAnsOpt(true)
    setClickedAns(option)
    setIsShowAnswer(true)
    let temCnt = currentQuestionNo
    temCnt = temCnt + 1

    setTimeout(() => {
      loadQuestion(qid,option)
    }, 1000);
    setIsLoading(false)
  }

  const handleViewWallet = () => {
    history.push('/wallet')
  }

  const handleNoNewQuestions = (message) => {
    confirmAlert({
      title: '',
      message: message,
      buttons: [
        {
          label: 'ok',
          onClick: () => console.log(message)
        }
      ]
    });
  }

  const handleTimeExpiry = () => {
    confirmAlert({
      title: 'Oops! Times up',
      message: '',
      buttons: [
        {
          label: 'ok',
          onClick: () => console.log('Oops! Times up')
        }
      ]
    });
  }

  const handleContinueExam = () => {
    setLevelOfQuiz(levelOfQuiz+1)
    setIsDisableAnsOpt(false)
    setIsShowAnswer(false)
    handleStartExam()
  }

  const handlePauseExam = () => {
    submitQuiz(3)
  }

  const handleNewQuiz = () => {
    setSelectedTopic('')
    setTopicQuestions('')
    setIsExamSubmitted(false)
    setIsShowScoreBoard(false)
    setIsStartExam(false)
  }

    return ( 
    <>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Quiz</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              { !isExamSubmitted && !isShowScoreBoard && 
              <div className="card-header p-2">
                { !isExamSubmitted && selectedTopic !== '' && 
                  <button onClick={() => handleBackClick()} type="button" className="btn btn-default"><i className="fas fa-arrow-left" /></button>
                }
                { selectedTopic === '' && <>Select Topic</> }
                { selectedTopic !== '' && <>{selectedTopic.name}</> }                
              </div>
              }
              <div className="card-body">
                { isLoading && <PageLoader/> }
                { !isLoading && !isStartExam && selectedTopic !=='' && !isLevelCompleted && <>
                  <div className='row'>
                    <div className='col-md-12 col-sm-12 col-12'>
                      <div style={{margin: 0, padding: 0, overflow: 'hidden'}}>
                        <div dangerouslySetInnerHTML={instructions} ></div>
                        <iframe id="myIframe" src="https://jjedutech.in:8843/app/quiz/instructions/index.html" allowFullScreen frameBorder={0} style={{'width':'100%'}} />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="button" onClick={() => getQuestions()}  className="btn btn-warning mt-20 brand-primary-btn">Start Quiz</button> 
                  </div>
                  </>
                }

                { !isLoading && !isStartExam && selectedTopic ==='' && !isLevelCompleted && <>

                  <div className="row">
                    <div className='col-md-4 col-sm-6 col-12'>
                      <img className='w-100p' src='/dist/img/JiJi.png' />
                    </div>
                    <div className='col-md-8 col-sm-6 col-12'>
                      <p style={{'marginTop':'15px', 'marginBottom':'30px'}}>Test your knowledge and earn rewards!</p>
                      <div className='row'>
                        { Object.entries(topicList).length > 0 && topicList.map((item,index) => {
                          return (                  
                            <div className="col-md-4 col-sm-6 col-12 clickable" key={index} onClick={() => handleTopicClick(item)}>
                              <button type="button" className="btn btn-block btn-warning btn-sm quiz-topics w90p mb-20">{item.name}</button>
                            </div>
                          )
                        }) }
                      </div>
                    </div>
                    
                  </div>
                  <AdBanners adBanners={adBanners} />
                  </>
                }

                { !isExamSubmitted && isStartExam && <>

                   { topicQuestions.levels[levelOfQuiz] && topicQuestions.levels[levelOfQuiz].questions.map((item, index) => {
                    
                    if(currentQuestionNo === index){
                      return ( <div key={index}>
                        <div className='row'>
                          <div className='col'>
                            <p className='text-info'>{item.subtopic}</p>
                          </div>
                          <div className='col'>
                            <div className='text-right'>
                              <div className='timer'>
                                <Timer expiryTimestamp={quizDuration} callBackFunction={() => {submitQuiz(4); handleTimeExpiry();}} />
                              </div>
                              <div className="quiz-counter">Question {currentQuestionNo+1} of {totalQuestion}</div>
                            </div>
                          </div>
                        </div>
                        <div id="questions">
                          <div className="question-container">
                            <p className="question">{item.content}</p>
                            <ul className={`answers ${ isDisableAnsOpt ? 'unavailable' : ''}`}>
                              { item.options.map((ops,ind) => {
                                return(
                                  <li key={ind}><a className={`${ isShowAnswer && ops.answer === true ? 'correct' : ''} ${ isShowAnswer && clickedAns === ops.name && ops.answer === false ? 'incorrect' : ''}`} data-index={ind} onClick={() => { setIsLoading(true); handleAnswerClick(item.question_id,ops.name); }}>{ops.content}</a></li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                        </div>
                      )}
                   }) 
                   }
                </>
                }

                { isLevelCompleted && <>
                  <img src={`${baseURL()}files/system?file=${topicQuestions.levels[levelOfQuiz].settings.asset}`} />
                  <div className="text-center">
                      <button type="button" onClick={() => handlePauseExam()}  className="btn btn-warning mt-20 brand-primary-btn mr-10">Pause</button> 
                      <button type="button" onClick={() => handleContinueExam()}  className="btn btn-warning mt-20 brand-secondary-btn">Continue</button> 
                  </div>
                </> }

                { isExamSubmitted && isShowScoreBoard && <>
                  <div className='exam-result row'>
                  <div className='col-md-12 col-sm-12 col-12 text-center'>
                    { finalScore.total_answered > 0 && <>
                      <img src='/dist/img/cup.svg' className='cup-img' />
                      <h2 className='quiz-title'>Congratulations!</h2>
                      </>
                    }

                    { finalScore.total_answered === 0 && <>
                      <img src='/dist/img/character-sad.svg'/>
                      <h2>You did your best</h2>
                      <p>Probably you will need to try different ways to make for success.</p>
                      </>
                    }
                      
                  </div>
                  <div className='col-md-12 col-sm-12 col-12 text-center'>
                    <p>Quiz result</p>
                    <div className='coins-board'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-12 text-center tbl-border btl'>
                          <p className='title'>Your Quiz Score</p>
                          <p className='value'><span className='brand-second-color'>{finalScore.total_answered}</span>/{finalScore.total_questions}</p>
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 text-center tbl-border btr'>
                        <p className='title'>Earned Coins</p>
                        <p className='value'><img src='/dist/img/coin.svg'/> {finalScore.coins_earned}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-4 col-sm-4 col-12 text-center tbl-border bbl'>
                        <p className='title'>Time Taken</p>
                        <p className='value'>{finalScore.time_taken} sec</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-12 text-center tbl-border'>
                        <p className='title'>Speed</p>
                        <p className='value'>{finalScore.speed}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-12 text-center tbl-border bbr'>
                        <p className='title'>Accuracy</p>
                        <p className='value'>{finalScore.accuracy}</p>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="text-center">
                        <button type="button" onClick={() => handleViewWallet()}  className="btn btn-warning mt-20 brand-primary-btn mr-10">VIEW WALLET</button> 
                        <button type="button" onClick={() => handleNewQuiz()}  className="btn btn-warning mt-20 brand-secondary-btn">TAKE NEW QUIZ</button> 
                    </div>
                      <br/>
                      <br/>
                  </div>                  
                </div>
                </>
                }
              </div>
            </div>
            
          </div>
        </section>
        
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </>)
}

export default QuizExam