import React from 'react';
import Spinner from '../Spinner';
import StyledPageLoader from './Styles';

const PageLoader = (props) => (
  <StyledPageLoader className={props.padding}>
    <Spinner size={40} />
  </StyledPageLoader>
);

export default PageLoader;
