import React, {useEffect, useState}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { appURL, baseURL, getToken, getUser, removeUserSession } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import Axios from 'axios';

function PaymentResponse (props) {
  const user = getUser();
  const token = getToken();
  const history = useHistory();
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [courseList, setCourseList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedLevel, setSelectedLevel] = useState({})
  const [selectedSubject, setSelectedSubject] = useState({})
  const [selectedTutor, setSelectedTutor] = useState({})
  const { addToast } = useToasts();
  const [testseriesData, setTestseriesData] = useState({})

  console.log(history.location.state)
  const [txnid, setTxnId] = useState('');
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    Axios.get(`${appURL()}/getResponse`).then(res=>{
      setTxnId(res.data.txnid);
      setAmount(res.data.amount);
      setStatus(res.data.status);
    });
  }, [txnid, amount, status]);

  console.log(txnid, amount, status)

const handleCourseClick = (courseId) => {
  const selCourse = courseList.filter((c) => c.course_id === courseId)
  setSelectedCourse(selCourse)
}

const handleBackClick = () => {

  if(selectedSubject.length > 0)
  {
    setSelectedSubject({})
  }else if(selectedLevel.length > 0)
  {
    setSelectedLevel({})
  }
  else if(selectedCourse.length > 0)
  {
    setSelectedCourse({})
  }
}

const handleLevelClick = (levelId) => {
  const levelSubjects = selectedCourse[0].levels.filter((lev) => lev.level_id === levelId)
  setSelectedLevel(levelSubjects)
}

const handleSubjectClick = (subjectId) => {
const subjects = selectedLevel[0].subjects.filter((sub) => sub.subject_id === subjectId)
setSelectedSubject(subjects)
}

const handleTutorClick = (tutorId) => {
   setSelectedTutor(tutorId)
   setIsLoading(true)
   api.get(`tutor/v1/${tutorId}/subject/${selectedSubject[0].subject_id}/content/testseries?user_id=${user.user_id}`).then(response => {
     setIsLoading(false);
     setTestseriesData(response.data)
   }).catch(error => {
     setIsLoading(false);
     addToast(error, { appearance: 'error', autoDismiss: true });
   });
}

  const handleLogout = () => {
    removeUserSession();
    props.history.push('/');
  }

    return ( 
    <div>
      <Header/>
      <Menu/>
        {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Notes</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header p-2">
                  Dummy Data
              </div>
              <div className="card-body">
                
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer/>
    </div>)
}

export default PaymentResponse