import React, {Fragment, useEffect, useState, useRef}  from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Components/Header';
import Menu from '../Shared/Components/Menu';
import { useToasts } from 'react-toast-notifications';
import Footer from '../Shared/Components/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { baseURL, getToken, getUser } from '../Shared/Utils/Common';
import api from '../Shared/Utils/api';
import moment from 'moment';

function MyProfile (props) {
  const user = getUser();
  const token = getToken();
  const history = useHistory();
  const [isApiCompleted, setIsApiCompleted] = useState(false)
  const [recentActivity, setRecentActivity] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const [orderDetails, setOrderDetails] = useState('')
  const [verifiedOrderIds,setVerifiedOrderIds] = useState([])
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null);

  useEffect(() => {
    if(!isApiCompleted)
    {
      api.get(`user/v1/journal`).then(function (response) {
        setRecentActivity(response.data)
      }).catch(error => {
        addToast(error.message, { appearance: 'error',autoDismiss: true });
      });
      setIsApiCompleted(true)
    }
  })

  const handleProfileImageClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    
    const formData = new FormData();
    formData.append('file', fileObj);
    setLoading(true);
    api.post(`user/v1/profile/avatar/${user.user_id}`, formData).then(function (response) {
      let userObj = user
      userObj.avatar = response.data.avatar
      sessionStorage.setItem('user', JSON.stringify(userObj));
      addToast('Profile picture updated successfully.', { appearance: 'success', autoDismiss: true})
      setLoading(false)
      
    }).catch(error => {
      setLoading(false)
      addToast(error.message, { appearance: 'error',autoDismiss: true });
    });    
  };

const getOrderDetails = (orderId) => {
  setOrderDetails('')
  let existingIds = [...verifiedOrderIds];
  let i = existingIds.findIndex(o => o.order_id === orderId)
  if(i===-1)
  {
    existingIds.push({'order_id':orderId})
    setVerifiedOrderIds(existingIds)
    api.get(`user/cart/v1/order/${orderId}`).then(function (response) {
      setOrderDetails(response.data)
    }).catch(error => {
      addToast(error, { appearance: 'error',autoDismiss: true });
    });
  }
}

  const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().min(6, 'Should be more than 6 character').required('Required'),
    new_password: Yup.string().min(6, 'Should be more than 6 character').required('Required'),
    confirm_password: Yup.string().min(6, 'Should be more than 6 character').required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match')
  });

  const ProfileUpdateSchema = Yup.object().shape({
    displayname: Yup.string().min(2, 'Too short!').required('Required')
  });

    return ( <>
      <Header/>
      <Menu/>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Profile</h1>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-primary card-outline mb-15">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      <input
                      style={{display: 'none'}}
                      ref={inputRef}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <div onClick={handleProfileImageClick}>
                      { user.avatar && user.avatar!=='' &&
                        <img src={`${baseURL()}files/user/${user.user_id}?file=${user.avatar}`} className="img-circle elevation-2" alt="User Image" style={{'width': '100%'}} />
                      }
                      { !user.avatar &&
                        <img className="profile-user-img img-fluid img-circle" src="../../dist/img/avatar-5.png" alt="User profile picture" />
                      } 
                    </div>
                    </div>
                    <h3 className="profile-username text-center">{user.displayname}</h3>
                    {/* <p className="text-muted text-center">Software Engineer</p> */}
                    <ul className="list-group list-group-unbordered mb-3">
                    
                      <li className="list-group-item">
                        <b>Subscriptions</b> <a className="float-right">{Object.entries(user.subscriptions).length}</a>
                      </li>
                      <li className="list-group-item">
                        <b>My Courses</b> <a className="float-right">{Object.entries(user.mycourses).length}</a>
                      </li>
                      <li className="list-group-item">
                        <b>My Quiz</b> <a className="float-right">{Object.entries(user.myquiz).length}</a>
                      </li>
                      <li className="list-group-item">
                        <b>My Wallet</b> <a className="float-right">{Object.entries(user.mywallet).length}</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title color-white">About Me</h3>
                  </div>
                  <div className="card-body">
                    <i className="fas fa-mobile mr-1" /> Mobile
                    <p className="text-muted">{user.phone}</p>
                    <hr />
                    <i className="fas fa-envelope mr-1" /> Email
                    <p className="text-muted">{user.email}</p>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header p-2">
                    <ul className="nav nav-pills">
                      <li className="nav-item"><a className="nav-link active" href="#profile" data-toggle="tab">Profile</a></li>
                      <li className="nav-item"><a className="nav-link" href="#change-password" data-toggle="tab">Change password</a></li>
                      <li className="nav-item"><a className="nav-link" href="#activity" data-toggle="tab">Activity</a></li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div className="tab-content">
                      
                      <div className="active tab-pane" id="profile">
                      <Formik
                            initialValues={
                              { 
                                displayname: user.displayname,
                                email: user.email,
                                phone: user.phone,
                              }
                            }
                            validationSchema={ProfileUpdateSchema}
                            onSubmit = {(values, {resetForm}) => {                           
                                setLoading(true);
                               api.put(`user/v1/profile/${user.user_id}`,{"displayname": values.displayname}).then(function (response) {
                                let userObj = user
                                userObj.displayname = values.displayname
                                  setLoading(false)
                                  if(response.status === 200){
                                    addToast('Profile updated successfully', { appearance: 'success', autoDismiss: true })
                                    sessionStorage.setItem('user', JSON.stringify(userObj));
                                  }
                                }).catch(error => {
                                  setLoading(false)
                                  addToast('Error', {appearance: 'error', autoDismiss: true });
                                });
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <div className="form-group row">
                                  <label htmlFor="Currentpassword" className="col-sm-2 col-form-label">Fullname</label>
                                  <div className="col-sm-5">
                                    <Field type="text"
                                      name='displayname'
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      defaultValue={user.displayname}
                                       />
                                      <ErrorMessage name="displayname" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label htmlFor="Currentpassword" className="col-sm-2 col-form-label">Email</label>
                                  <div className="col-sm-5">
                                    <Field type="text"
                                      name='email'
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={user.email}
                                      readOnly
                                      disabled />
                                      <ErrorMessage name="email" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label htmlFor="Currentpassword" className="col-sm-2 col-form-label">Phone</label>
                                  <div className="col-sm-5">
                                    <Field type="text"
                                      name='phone'
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={user.phone}
                                      readOnly
                                      disabled />
                                      <ErrorMessage name="phone" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                                
                                
                                <div className="form-group row">
                                  <div className="offset-sm-2 col-sm-10">
                                    <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Submit'}</button>
                                  </div>
                                </div>
                          </Form>
                          )}
                        </Formik>
                      </div>
                      <div className="tab-pane" id="change-password">
                        <Formik
                            initialValues={
                              { 
                                old_password: '',
                                new_password: '',
                                confirm_password: '',
                              }
                            }
                            validationSchema={ChangePasswordSchema}
                            onSubmit = {(values, {resetForm}) => {                           
                                setLoading(true);
                               api.put(`user/v1/password/${user.user_id}`,values).then(function (response) {
                                  setLoading(false)
                                  if(response.status === 200){
                                    addToast('Password changed successfully', { appearance: 'success', autoDismiss: true })
                                  }
                                }).catch(error => {
                                  setLoading(false)
                                  addToast('Error', {appearance: 'error', autoDismiss: true });
                                });
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <div className="form-group row">
                                  <label htmlFor="Currentpassword" className="col-sm-3 col-form-label">Current password</label>
                                  <div className="col-sm-5">
                                    <Field type="password"
                                      name='old_password'
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.old_password} />
                                      <ErrorMessage name="old_password" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label htmlFor="Newpassword" className="col-sm-3 col-form-label">New password</label>
                                  <div className="col-sm-5">
                                    <Field type="password"
                                      name='new_password'
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.new_password} />
                                      <ErrorMessage name="new_password" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label htmlFor="Confirmpassword" className="col-sm-3 col-form-label">Confirm password</label>
                                  <div className="col-sm-5">
                                    <Field type="password"
                                      name='confirm_password'
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.confirm_password}/>
                                      <ErrorMessage name="confirm_password" component="div" className='mandatory_error' />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="offset-sm-2 col-sm-10">
                                    <button type="button" onClick={handleSubmit} disabled={loading} className="btn brand-secondary-btn">{loading ? 'Loading...' : 'Submit'}</button>
                                  </div>
                                </div>
                          </Form>
                          )}
                        </Formik>
                      </div>
                      <div className="tab-pane" id="activity">
                      <div className="timeline timeline-inverse">
                          {recentActivity.length > 0 && recentActivity.map((activity,key) => {
                            if(activity.type === 'Paid' && activity.data && activity.data.order_id !== undefined)
                            {
                              console.log(activity)
                              const myArray = activity.data.order_id.split("_");
                              let OrderDetails = getOrderDetails(myArray[1])
                            }
                            
                            return (
                          <Fragment key={key}>
                            <div>
                              { activity.category === 'Courses' &&
                                <i className="fas fa-book bg-green" />
                              }
                              { activity.category === 'Cart' && activity.type === 'Paid' && <>
                              <i className="fas fa-check bg-green" />
                              </> }
                              { activity.category === 'Cart' && activity.type !== 'Paid' && <>
                              <i className="fas fa-shopping-cart bg-primary" />
                              </> }
                              <div className="timeline-item">
                                <span className="time"><i className="far fa-calendar" /> {moment(activity.dt).format('MMM D, YYYY')}  <i className="far fa-clock" />  {moment(activity.dt).format('h:mm A')}</span>
                                <h3 className="timeline-header"><a href="#">{activity.category}</a></h3>
                                <div className="timeline-body">
                                  {activity.summary}
                                  {orderDetails!=='' && <>
                                  {orderDetails.order_status}
                                  {orderDetails.order_id}
                                  </>}
                                </div>
                              </div>
                            </div>
                          </Fragment>
                            )
                            }) 
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </>)
}

export default MyProfile